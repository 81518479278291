//consignee-->收件方
//shipper-->寄件方
//consigneeCatalog123-->收件方資料會存在寄件方電話底下   目前好像沒有用到可以刪掉  我先改了node名，如果沒有問題就可以刪掉

import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import {todayTitle,IDSaveShipper,IDSaveCog,IDSaveRec} from './helper/SaveToDatabase';
import {ref} from '../firebase/firebaseConfig';
import {LogoImg,textToBase64Barcode} from './helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';

import {Segment,Table,Grid,Label,Form,Checkbox,Radio,Button,Input,Modal,Icon,Header, CardContent} from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';
var JsBarcode = require('jsbarcode');

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


function FloatAdd(arg1, arg2)
{
  var r1, r2, m;
  try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
  try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
  m = Math.pow(10, Math.max(r1, r2));
  return (FloatMul(arg1, m) + FloatMul(arg2, m)) / m;
}

function FloatMul(arg1, arg2)
{
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
  try { m += s1.split(".")[1].length; } catch (e) { }
  try { m += s2.split(".")[1].length; } catch (e) { }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

function FloatDiv(arg1, arg2)
{
  var t1 = 0, t2 = 0, r1, r2;
  try { t1 = arg1.toString().split(".")[1].length } catch (e) { }
  try { t2 = arg2.toString().split(".")[1].length } catch (e) { }
  
    r1 =Number(arg1.toString().replace(".", ""))
    r2 =Number(arg2.toString().replace(".", ""))
    return (r1 / r2) * Math.pow(10, t2 - t1); 
}

function CalculateFee(orderTotalWeight,orderVolumeWeight,price){
    var targetWeight;
    var orderTotalFee;
    
    if(orderTotalWeight>orderVolumeWeight){
      targetWeight=orderTotalWeight
    }else{
      targetWeight=orderVolumeWeight
    }
    
    if(targetWeight<1){
       orderTotalFee=price
    }else{
      var halfPrice=FloatDiv(Number(price),2)
      //console.log(halfPrice)
      orderTotalFee=halfPrice*Math.ceil(FloatDiv((Number(targetWeight)-1),0.5))
      //console.log(orderTotalFee)
      orderTotalFee=FloatAdd(Number(orderTotalFee),Number(price))
      // 做四捨五入
      // orderTotalFee=Math.round(orderTotalFee)
    }
    return orderTotalFee
}

function exportExcelFile(allPO,printPO,today,taxStatus){
  //先開一個檔案出來
  var workbook = new Excel.Workbook();
  //做第一張表EXPRESS  MANIFEST
  var worksheet = workbook.addWorksheet('EXPRESS  MANIFEST');
  worksheet.mergeCells('A1:M1');
  worksheet.getCell('D1').value = 'EXPRESS  MANIFEST';
  worksheet.getCell('D1').font = {
      size: 28,
      bold: true
  };
  worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('A2').value = 'CONSIGN TO:';
  worksheet.getCell('B2').value = 'PT. TATA HARMONI SARANATAMA';
  worksheet.getCell('A3').value = 'MAWB NO:';
  
  worksheet.getCell('A4').value = 'FLIGHT NO:';

  worksheet.getCell('A5').value = 'FROM';
  worksheet.getCell('B5').value = 'TWN';
  worksheet.getCell('A6').value = 'TO';
  worksheet.getCell('B6').value = 'JKT';
  worksheet.getCell('F2').value = 'DEC LOGISTICS LTD.';
  worksheet.getCell('F3').value = 'TEL:02-25182030';
  
  worksheet.columns = [
      { width: 20 },
      { width: 10 },
      { width: 40 },
      { width: 40 },
      { width: 10 },
      { width: 25 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 20 },
      { width: 10 },
    ];
  worksheet.addRow(['No', 'Hawb No','SHIPPER','CNEE','PKG','DESCRIPTION','PCS','KG','VALUE\r\n(USD)','PP\r\n(TWD)','CC\r\n(TWD)','REMARKS','STATION']);			
  worksheet.getCell('A7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('B7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('C7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('D7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('E7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('F7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('G7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('H7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('I7').alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('J7').alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('K7').alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('L7').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('M7').alignment = { vertical: 'middle', horizontal: 'center' };


  var i=8
  printPO.forEach(function(item){
      //先把要印的單張訂單目標導進來
      var target=allPO[item];

      var No=''
          if(target.orderLoadingBox2){
            No='JKT-'+target.orderLoadingBox1+'~'+target.orderLoadingBox2
          }else{
            No='JKT-'+target.orderLoadingBox1
          }

      var Hawb=target.orderDeliveryNumber
      var SHIPPER=target.shipperEngName+'\r\n'+target.shipperEngAddress+'\r\n'+'E-mail:'+'\r\n'+'TEL:'+target.shipperPhoneNumber+'#'+target.shipperPhoneExtNumber+'\r\n'+'Attn:'+target.shipperContact
      var CNEE=target.cogEngName+'\r\n'+'NPWP:'+target.cogNPWP+'\r\n'+target.cogEngAddress+'\r\n'+'HS Code:'+target.itemHScode1+'\r\n'+'TEL:'+target.cogPhoneAreaNumber+'-'+target.cogPhoneNumber+'#'+target.cogPhoneExtNumber+'\r\n'+'Attn:'+target.cogContact
      var PKG=Number(target.orderPackageNumber)
      var DESCRIPTION=''
          for(var k=0; k<target.itemPcNumber ;k++){
            var number=k+1
            DESCRIPTION=DESCRIPTION+target['itemName'+number]+'*'+target['itemNumber'+number]+'/'
          }   

      var PCS=Number(target.totalPCs)
      var KG=Number(target.orderTotalWeight)
      var VALUE=Number(target.totalValue)
      var PP=''
      var CC=''
      
        if(target.orderPayWay=='PP'){
          PP=target.orderTotalFee
          CC=0
        }else{
          CC=target.orderTotalFee
          PP=0
        }
      var orderChargeFee=''
        if(target.orderChargeExtFee){
          orderChargeFee=target.orderChargeFee+'+'+target.orderChargeExtFee
        }else{
          orderChargeFee=target.orderChargeFee
        }

      var REMARKS=target.orderPassWay+'\r\n'+orderChargeFee+'/KG'+'\r\n'+target.sizeNote+'\r\n'+target.orderNote
      var STATION=''
      worksheet.addRow([No,Hawb,SHIPPER,CNEE,PKG,DESCRIPTION,PCS,KG,VALUE,PP,CC,REMARKS,STATION]);
      worksheet.getCell('A'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('B'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C'+i).alignment = { wrapText: true,vertical: 'middle' };
      worksheet.getCell('D'+i).alignment = { wrapText: true,vertical: 'middle' };
      worksheet.getCell('E'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('F'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('G'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('H'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('I'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('J'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('K'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('L'+i).alignment = { wrapText: true,vertical: 'middle' };
      worksheet.getCell('M'+i).alignment = { vertical: 'middle', horizontal: 'center' };
      i++
  });
  
  //做第二張表Tata_EXPRESS_
  var WS2 = workbook.addWorksheet('Tata_EXPRESS_');
  WS2.columns = [
      { header: 'No', key: 'No', width: 20 },
      { header: 'HAWB No', key: 'HAWB No', width: 20 },
      { header: 'Shipper Name', key: 'Shipper Name', width: 20 },
      { header: 'Shipper Address', key: 'Shipper Address', width: 20 },
      { header: 'Consignee Name', key: 'Consignee Name', width: 20 },
      { header: 'Consignee Address', key: 'Consignee Address', width: 20 },
      { header: 'PKG', key: 'PKG', width: 20 },
      { header: 'Description', key: 'Description', width: 20 },
      { header: 'HS CODE', key: 'HS CODE', width: 20 },
      { header: 'PCS', key: 'PCS', width: 20 },
      { header: 'KG', key: 'KG', width: 20 },
      { header: 'FOB Value', key: 'FOB Value', width: 20 },
      { header: 'CN', key: 'CN', width: 20 },
      { header: 'consignee City', key: 'consignee City', width: 20 },
      { header: 'Consignee Region', key: 'Consignee Region', width: 20 },
      { header: 'Consignee ZIP Code', key: 'Consignee ZIP Code', width: 20 },
      { header: 'Contact Person', key: 'Contact Person', width: 20 },
      { header: 'Contact Phone', key: 'Contact Phone', width: 20 },
    ];
    WS2.getCell('A1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('B1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('C1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('D1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('E1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('F1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('G1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('H1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('I1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('J1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('K1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('L1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('M1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('N1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('O1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('P1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('Q1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    WS2.getCell('R1').fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFFFFF00'},
      bgColor:{argb:'FF0000FF'}
    };
    
    i=2
    printPO.forEach(function(item){
          //先把要印的單張訂單目標導進來
          var target=allPO[item];

          var No=''
              if(target.orderLoadingBox2){
                No='JKT-'+target.orderLoadingBox1+'~'+target.orderLoadingBox2
              }else{
                No='JKT-'+target.orderLoadingBox1
              }

          var Hawb=target.orderDeliveryNumber
          var Shipper_Name=target.shipperEngName
          var Shipper_Address=target.shipperEngAddress
          var Consignee_Name=target.recEngName
          var Consignee_Address=target.recEngAddress
          var PKG=Number(target.orderPackageNumber)
          var Description=''
          var HS_CODE=''
          for(var k=0; k<target.itemPcNumber ;k++){
              var number=k+1
              Description=Description+target['itemName'+number]+'/'
              if(target['itemHScode'+number]){
                if(target['itemHScode'+number]!==''){
                   HS_CODE=HS_CODE+target['itemHScode'+number]+'/'
                }
              }else{
                 var space='    _ '
                 HS_CODE=HS_CODE+space+'/'
                 
              }
          }   
          var PCS=Number(target.totalPCs)
          var KG=Number(target.orderTotalWeight)
          var FOB_Value=Number(target.totalValue)
          var CN=target.orderPassWay
          var consignee_City=target.recEngAddressCity
          var Consignee_Region=target.recEngAddressRegion
          var ZIPCode=target.recZipCode
          var Contact_Person=target.recContact
          var Contact_Phone=target.recPhoneAreaNumber+'-'+target.recPhoneNumber+'#'+target.recPhoneExtNumber




          WS2.addRow([
              No,
              Hawb,
              Shipper_Name,
              Shipper_Address,
              Consignee_Name,
              Consignee_Address,
              PKG,
              Description,
              HS_CODE,
              PCS,
              KG,
              FOB_Value,
              CN,
              consignee_City,
              Consignee_Region,
              ZIPCode,
              Contact_Person,
              Contact_Phone
          ]);
          WS2.getCell('A'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('B'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('C'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('D'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('E'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('F'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('G'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('H'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('I'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('J'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('K'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('L'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('M'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('N'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('O'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('P'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('Q'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS2.getCell('R'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          i++
    });
  
  //做第三張表CGK出貨明細
  var WS3 = workbook.addWorksheet('CGK出貨明細');
  WS3.mergeCells('A1:H1');
  WS3.getCell('D1').value = 'DEC跨境通物流-CGK出貨明細';
  WS3.getCell('D1').font = {
      size: 28,
      bold: true
  };
  WS3.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('B2').value = '日期:';
  WS3.getCell('C2').value = today;
  
  
  WS3.columns = [
      { width: 10 },
      { width: 10 },
      { width: 25 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      
    ];
  WS3.addRow(['袋號', '件','提單號','品名(Description)','毛重KG','寄件人名稱','(正式，簡易)','備註']);			
  WS3.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('B3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('C3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('D3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('E3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('F3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('G3').alignment = { vertical: 'middle', horizontal: 'center' };
  WS3.getCell('H3').alignment = { vertical: 'middle', horizontal: 'center' };
  
  i=4
  printPO.forEach(function(item){
        //先把要印的單張訂單目標導進來
        var target=allPO[item];
        var No=''
          if(target.orderLoadingBox2){
            No=target.orderLoadingBox1+'~'+target.orderLoadingBox2
          }else{
            No=target.orderLoadingBox1
          }

        var PCS=target.orderPackageNumber
        var Hawb=target.orderDeliveryNumber
        var Description=target.itemName1
        var KG=Number(target.orderTotalWeight)
        var Shipper_Name=target.shipperChName
        var A=''
        if (target.shipperCustomsClearance) {
          if (target.shipperCustomsClearance === 'simple') {
              A = '簡易';
          } else {
              A = '正式';
          }
       }
        var B=target.shipperAccount
    


        WS3.addRow([
            No,
            PCS,
            Hawb,
            Description,
            KG,
            Shipper_Name,
            A,
            B
        ]);
        WS3.getCell('A'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('B'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('C'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('D'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('E'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('F'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('G'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        WS3.getCell('H'+i).alignment = { vertical: 'middle', horizontal: 'center' };
        
        i++
  });
   
  //做第四張表上傳公司表單
  var WS4 = workbook.addWorksheet('印尼上傳公司系統訂單');
  WS4.columns = [
      { header: 'HAWB', key: 'HAWB', width: 20 },
      { header: '單號', key: '單號', width: 20 },
      { header: '寄件人', key: '寄件人', width: 20 },
      { header: '寄件地址', key: '寄件地址', width: 20 },
      { header: '收件人', key: '收件人', width: 20 },
      { header: '收件地址', key: '收件地址', width: 20 },
      { header: 'PKG', key: 'PKG', width: 20 },
      { header: '內容物', key: '內容物', width: 20 },
      { header: '件數', key: '件數', width: 20 },
      { header: '公斤數', key: '公斤數', width: 20 },
      { header: '報關價值', key: '報關價值', width: 20 },
      { header: 'Remark', key: 'Remark', width: 20 },
      { header: 'consignee City', key: 'consignee City', width: 20 },
      { header: 'Consignee Region', key: 'Consignee Region', width: 20 },
      { header: 'Consignee ZIP Code', key: 'Consignee ZIP Code', width: 20 },
      { header: 'Contact Person', key: 'Contact Person', width: 20 },
      { header: '連絡電話', key: '連絡電話', width: 20 },
      { header: 'Personal ID / KTP', key: 'Personal ID / KTP', width: 20 },
      { header: 'Reference Number', key: 'Reference Number', width: 20 },
      { header: 'DOX/SPX', key: 'DOX/SPX', width: 20 },
      { header: 'CC/PP', key: 'CC/PP', width: 20 },
      { header: 'CC Amount', key: 'CC Amount', width: 20 },
      { header: 'Currency', key: 'Currency', width: 20 },
    ];

  
    i=2
    printPO.forEach(function(item){
          //先把要印的單張訂單目標導進來
          var target=allPO[item];

          var Hawb=''
          var orderNumber=target.orderDeliveryNumber
          var Shipper_Name=target.shipperChName
          var Shipper_Address=target.shipperEngAddress
          var Consignee_Name=target.recEngName
          var Consignee_Address=target.recEngAddress
          var PKG=''
          var Description=''
              for(var k=0; k<target.itemPcNumber ;k++){
                var number=k+1
                Description=Description+target['itemName'+number]+'*'+target['itemNumber'+number]+'/'
              }  
          var PCS=Number(target.orderPackageNumber)
          var KG=Number(target.orderTotalWeight)
          var FOB_Value=Number(target.totalValue)
          var Remark=target.sizeNote+'\r\n'+target.orderNote

          if (target.shipperCustomsClearance) {
              if (target.shipperCustomsClearance === 'simple') {
                  Remark = '簡易 ' + Remark;
              } else if (target.shipperCustomsClearance === 'formal') {
                  Remark = '正式 ' + Remark;
              } 
          }
          
          var consignee_City=target.recEngAddressCity
          var consignee_Region=target.recEngAddressRegion
          var ZIPCode=target.recZipCode
          var Contact_Person=target.recContact
          var Contact_Phone=target.recPhoneAreaNumber+'-'+target.recPhoneNumber+'#'+target.recPhoneExtNumber
          var ID=''
          var ReferenceNumber=''
          var passway=''
          var CC_PP=''
          var CC_Amount=''
          var Currency=''

          if(target.orderPayWay=='PP'){
            CC_PP='PP'
    
          }else{
            CC_PP='CC'
            CC_Amount=target.orderTotalFee
            // 這個東西有點小問題，因為我不懂為什麼王大哥不能用
            if (Number(target.orderChargeFee) > 100) {
                Currency = 'NTD';
            } else if (Number(target.orderChargeFee) < 100 || target.orderChargeFee === '') {
                Currency = 'USD';
            }
            console.log(Currency)
           
          }
          





          WS4.addRow([
              Hawb,
              orderNumber,
              Shipper_Name,
              Shipper_Address,
              Consignee_Name,
              Consignee_Address,
              PKG,
              Description,
              PCS,
              KG,
              FOB_Value,
              Remark,
              consignee_City,
              consignee_Region,
              ZIPCode,
              Contact_Person,
              Contact_Phone,
              ID,
              ReferenceNumber,
              passway,
              CC_PP,
              CC_Amount,
              Currency

          ]);
          WS4.getCell('A'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('B'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('C'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('D'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('E'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('F'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('G'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('H'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('I'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('J'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('K'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('L'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('M'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('N'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('O'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('P'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('Q'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('R'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('S'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('T'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('U'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('V'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          WS4.getCell('W'+i).alignment = { vertical: 'middle', horizontal: 'center' };
          i++
    });
  




  // 怎麼做換行
  // worksheet.getCell('A19').value = "1989\r\n1234";
  // worksheet.getCell('A19').alignment = { wrapText: true };
  var filename=`${today}-印尼通關Info-${taxStatus}.xlsx`
  workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      saveAs(blob, filename);
  });  
}

//我現在已經把標籤準備放到外面大家用
//因為最後一張標籤要做特別處理，所以分兩個
function normalLabel(target,withlogo){
  var logo=LogoImg();
  let Label=[]
  if(withlogo){
    Label=[
      { table: {
        widths: ['auto', '*', '*', '*', '*', '*'],
        //下面是要做一開始的logo、barcode、和國碼標
        body:[
          [
            {
            // you'll most often use dataURI images on the browser side
            // if no width/height/fit is provided, the original size will be used
            image:logo,
            width:20, 
            height:20,
            alignment: 'center',
            colSpan: 2},
            '',
            
            { 
              colSpan: 4,
              text: 'www.declogistics.com.tw',
              margin: [0, 4, 0, 0],  
              alignment: 'center',          
            },
            '',
            '',
            '',

          ],
          [{ image:textToBase64Barcode(target.orderDeliveryNumber),
            colSpan: 6,
            width:140, 
            height:50,
            alignment: 'center',
          },
          {},
          {},
          {},
          {},
          {}
          ],

          [{ text: 'SHPR:'},
          {text: target.shipperAccount+target.shipperChName+target.shipperEngName,
            colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{ text: 'SHPR ADD:'},
          {text:target.shipperEngAddress,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'SHPR TEL:'},
          {text: target.shipperPhoneNumber+'#'+target.shipperPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'SHPR ATTN:'},
          {text: target.shipperContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE:'},
          {text: target.recEngName,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE ADD:',rowSpan: 2},
          {text: target.recEngAddress,rowSpan: 2,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{},
          {},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE TEL:'},
          {text: target.recPhoneAreaNumber+'-'+target.recPhoneNumber+'#'+target.recPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE ATTN:'},
          {text: target.recContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'DECS.:'},
          {text: target.itemName1,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'WEIGHT:'},
          {text:target.orderTotalWeight,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'PKG:'},
          {text:target.orderPackageNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

        ] },
        pageBreak: 'after'
      }, 
    
    ];
  }else{
    Label=[
      { table: {
        widths: ['auto', '*', '*', '*', '*', '*'],
        //下面是要做一開始的logo、barcode、和國碼標
        body:[
          [
            { 
              colSpan: 6,
              text: { text: target.orderDeliveryNumber, fontSize: 15 },
              alignment: 'center',
              margin: [0, 4, 0, 0], 
              style: 'head'
            },
            {},
            {},
            {},
            {},
            {}
  
          ],
          [{ image:textToBase64Barcode(target.orderDeliveryNumber),
            colSpan: 6,
            width:140, 
            height:50,
            alignment: 'center',
          },
          {},
          {},
          {},
          {},
          {}
          ],
  
          [{ text: 'SHPR:'},
          {text: target.shipperAccount+target.shipperChName+target.shipperEngName,
            colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{ text: 'SHPR ADD:'},
          {text:target.shipperEngAddress,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'SHPR TEL:'},
          {text: target.shipperPhoneNumber+'#'+target.shipperPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'SHPR ATTN:'},
          {text: target.shipperContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE:'},
          {text: target.recEngName,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE ADD:',rowSpan: 2},
          {text: target.recEngAddress,rowSpan: 2,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{},
          {},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE TEL:'},
          {text: target.recPhoneAreaNumber+'-'+target.recPhoneNumber+'#'+target.recPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE ATTN:'},
          {text: target.recContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'DECS.:'},
          {text: target.itemName1,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'WEIGHT:'},
          {text:target.orderTotalWeight,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'PKG:'},
          {text:target.orderPackageNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
        ] },
        pageBreak: 'after'
      }, 
    
    ];
  }
  
  return Label
}

function lastLabel(target,withlogo){
  var logo=LogoImg();
  let Label=[]
  if(withlogo){
    Label=[
      { table: {
        widths: ['auto', '*', '*', '*', '*', '*'],
        //下面是要做一開始的logo、barcode、和國碼標
        body: [
          [
            {
            // you'll most often use dataURI images on the browser side
            // if no width/height/fit is provided, the original size will be used
            image:logo,
            width:20, 
            height:20,
            alignment: 'center',
            colSpan: 2},
            '',
            
            { 
              colSpan: 4,
              text: 'www.declogistics.com.tw',
              alignment: 'center',
              margin: [0, 4, 0, 0], 
                        
            },
            '',
            '',
            '',

          ],
          [{ image:textToBase64Barcode(target.orderDeliveryNumber),
            colSpan: 6,
            width:140, 
            height:50,
            alignment: 'center',
          },
          {},
          {},
          {},
          {},
          {}
          ],

          [{ text: 'SHPR:'},
          {text: target.shipperAccount+target.shipperChName+target.shipperEngName,
            colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{ text: 'SHPR ADD:'},
          {text:target.shipperEngAddress,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'SHPR TEL:'},
          {text: target.shipperPhoneNumber+'#'+target.shipperPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'SHPR ATTN:'},
          {text: target.shipperContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE:'},
          {text: target.recEngName,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE ADD:',rowSpan: 2},
          {text: target.recEngAddress,rowSpan: 2,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{},
          {},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE TEL:'},
          {text: target.recPhoneAreaNumber+'-'+target.recPhoneNumber+'#'+target.recPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

          [{text: 'CNEE ATTN:'},
          {text: target.recContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'DECS.:'},
          {text: target.itemName1,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'WEIGHT:'},
          {text:target.orderTotalWeight,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'PKG:'},
          {text: target.orderPackageNumber ,colSpan: 5},
          {},
          {},
          {},
          {}
          ],

        ]
      }},     
    ];
  }else{
    Label=[
      { table: {
        widths: ['auto', '*', '*', '*', '*', '*'],
        //下面是要做一開始的logo、barcode、和國碼標
        body: [
          [ { 
              colSpan: 6,
              text: { text: target.orderDeliveryNumber, fontSize: 15 },
              alignment: 'center',
              margin: [0, 4, 0, 0], 
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [{ image:textToBase64Barcode(target.orderDeliveryNumber),
            colSpan: 6,
            width:140, 
            height:50,
            alignment: 'center',
          },
          {},
          {},
          {},
          {},
          {}
          ],
  
          [{ text: 'SHPR:'},
          {text: target.shipperAccount+target.shipperChName+target.shipperEngName,
            colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{ text: 'SHPR ADD:'},
          {text:target.shipperEngAddress,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'SHPR TEL:'},
          {text: target.shipperPhoneNumber+'#'+target.shipperPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'SHPR ATTN:'},
          {text: target.shipperContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE:'},
          {text: target.recEngName,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE ADD:',rowSpan: 2},
          {text: target.recEngAddress,rowSpan: 2,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{},
          {},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE TEL:'},
          {text: target.recPhoneAreaNumber+'-'+target.recPhoneNumber+'#'+target.recPhoneExtNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
          [{text: 'CNEE ATTN:'},
          {text: target.recContact,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'DECS.:'},
          {text: target.itemName1,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'WEIGHT:'},
          {text:target.orderTotalWeight,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
          [{text: 'PKG:'},
          {text:target.orderPackageNumber,colSpan: 5},
          {},
          {},
          {},
          {}
          ],
  
        ]
      }},     
    ];
  }

  return Label
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
            // customerNumber:'test',
            customerNumber:'IDService',
            receiverTheSame: true,
            orderTaxWay: 'withTax',
            orderPassWay: 'PIBK',
            orderPayWay: 'PP',
            itemPcNumber:1,
            
            totalValue:0,
            saveFilefinish:false,
            deleteHistoryOpen:false,
            printPO:[],
            
            shipperCustomsClearance:'',
            shipperPhoneNumber:'',
            shipperPhoneExtNumber:'', 
            shipperContact:'',
            shipperAccount:'',
            shipperChName:'',
            shipperEngName:'',
            shipperChAddress:'',
            shipperEngAddress:'',

            orderDeliveryNumber:'',
            orderPackageNumber:'',
            orderTotalWeight:'',
            orderVolumeWeight:'',
            orderLoadingBox1:'',
            orderLoadingBox2:'',
            orderNote:'',      //這是備註
            orderChargeFee:'',
            pcBoxTotal:0,
            pcBoxSize:1,
            pcBox1:1,
            LBox1:'',
            WBox1:'',
            HBox1:'',
            sizeNote:'',    //這是尺寸訊息，配合excel輸出之用
            boxOrigialNumber:'red',
            boxAlreadySign:'yellow',
            

            cogPhoneAreaNumber:'', 
            cogPhoneNumber:'',
            cogPhoneExtNumber:'',
            cogContact:'',
            cogFaxNumber:'',
            cogEngName:'',
            cogNPWP:'',
            cogZipCode:'',
            cogEngAddressRegion:'',
            cogEngAddressCity:'',
            cogEngAddress:'',
            cogDatabase:'',
            orderChargeFee:300,
            orderChargeExtFee:'',
            orderTotalFee:300,
            currency:'NTD',

            
            recPhoneAreaNumber:'',
            recPhoneNumber:'',
            recPhoneExtNumber:'',
            recContact:'',
            recFaxNumber:'',
            recEngName:'',
            recNPWP:'',
            recZipCode:'',
            recEngAddressRegion:'',
            recEngAddressCity:'',
            recEngAddress:'',

            itemName1:'',
            itemHScode1:'',
            itemNumber1:'',
            itemUnitPrice1:'',
            totalPCs:0,


            cogChangePhoneAreaNumber:'', 
            cogChangePhoneNumber:'',
            cogChangePhoneExtNumber:'',
            cogChangeContact:'',
            cogChangeFaxNumber:'',
            cogChangeEngName:'',
            cogChangeNPWP:'',
            cogChangeZipCode:'',
            cogChangeEngAddressRegion:'',
            cogChangeEngAddressCity:'',
            cogChangeEngAddress:'',

            shipperDatabase:{},
            orderDeliveryNumberDatabase:{},
            // 我現在要分兩個state分別裝包稅和不包稅的訂單資料
            todayWithTaxPO:{},
            todayWithoutTaxPO:{},
            todayWithTaxPOContent:[],
            todayWithoutTaxPOContent:[],
            tempDataWithTaxOnScreen:[],
            tempDataWithoutTaxOnScreen:[],
            // 這個state是要秀在畫面上的，所以隨著radio的切換而改變
            todayPO:{},
            todayPOContent:[],
            tempDataOnScreen:[],
            
        
     };
    
    
    
    this.handleInputChange=this.handleInputChange.bind(this);
    this.handleShipperPhoneChange=this.handleShipperPhoneChange.bind(this);
    this.orderTaxWay=this.orderTaxWay.bind(this);
    this.orderPassWay=this.orderPassWay.bind(this);
    this.orderPayWay=this.orderPayWay.bind(this);
    this.orderTotalWeight=this.orderTotalWeight.bind(this);
    this.volumeWeightCalculate=this.volumeWeightCalculate.bind(this);
    this.addPcBoxSize=this.addPcBoxSize.bind(this);
    this.cutPcBoxSize=this.cutPcBoxSize.bind(this);
    this.pcBoxCalculate=this.pcBoxCalculate.bind(this);
    this.orderChargeFee=this.orderChargeFee.bind(this);
    this.orderChargeExtFee=this.orderChargeExtFee.bind(this);
    this.cogCheck=this.cogCheck.bind(this);
    this.handleCogPhoneChange=this.handleCogPhoneChange.bind(this)
    this.handleRecPhoneChange=this.handleRecPhoneChange.bind(this)
    this.addItem=this.addItem.bind(this);
    this.cutItem=this.cutItem.bind(this);
    this.handleBoxInputChange=this.handleBoxInputChange.bind(this)
    this.handleTotalPCs=this.handleTotalPCs.bind(this);
    this.handlePriceValueCalculate=this.handlePriceValueCalculate.bind(this);
    this.saveFile=this.saveFile.bind(this);
    this.handleSaveFileClose=this.handleSaveFileClose.bind(this);
    this.printPOChange=this.printPOChange.bind(this);
    this.poRefineClick=this.poRefineClick.bind(this);
    this.exportFile=this.exportFile.bind(this);
    this.labelPrint=this.labelPrint.bind(this);
    this.handleLablePrint=this.handleLablePrint.bind(this);
    this.deleteHistory=this.deleteHistory.bind(this);
    this.historyExportFile=this.historyExportFile.bind(this);
    this.historyExportLabel=this.historyExportLabel.bind(this);
    this.orderToTemp=this.orderToTemp.bind(this);
    this.tempToOrder=this.tempToOrder.bind(this);
    this.deleteFromTemp=this.deleteFromTemp.bind(this);
    this.searchCogData=this.searchCogData.bind(this);
    this.cogDataChangeSave=this.cogDataChangeSave.bind(this);
    this.searchOrder=this.searchOrder.bind(this);
    this.customsClearanceChange=this.customsClearanceChange.bind(this);
  };
  
  componentDidMount(){   
    //正常營運模式 
    let today=todayTitle()[0];
    let year=todayTitle()[1]
    let month=todayTitle()[2]
    let day=todayTitle()[3]
    
    this.setState({
      'today':today,
      'year':year,
      'month':month,
      'day':day
    });
        
    //Test模式
    // let today='20230922'
    // let year='2023'
    // let month='09'
    // let day='22'
    
    // this.setState({
    //   'today':today,
    //   'year':year,
    //   'month':month,
    //   'day':day
    // });    
    

    //今日訂單內容
    ref.child('/'+this.state.customerNumber+'/withTax/'+year+'/'+month+'/'+day).on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()){
              let todayWithTaxPO=snapshot.val();
              this.setState({todayWithTaxPO:todayWithTaxPO});
              let printWithTaxPO=[]
              //下面這個是在準備今日訂單的顯示介面
              var todayWithTaxPOContent = []; 
              var poNumber=1
              Object.keys(todayWithTaxPO).forEach((key)=> {
                  printWithTaxPO.push(key);
                  var po=todayWithTaxPO[key];
                  //都大寫
                  var PP=''
                  var CC=''
                  if(po.orderPayWay=='PP'){
                    PP=po.orderTotalFee
                    CC=0
                  }else{
                    CC=po.orderTotalFee
                    PP=0
                  }
                  todayWithTaxPOContent.push(
                      <Table.Row  style={{'height':44 }}>
                          <Table.Cell>
                          　　<Grid centered  style={{'padding-bottom':'0.8em' }}> <Checkbox defaultChecked id={po.orderDeliveryNumber} onClick={this.printPOChange}/></Grid>
                          </Table.Cell>
                          <Table.Cell >
                              <Grid centered style={{'padding-top':'0.8em' }}>{poNumber}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}>{po.orderDeliveryNumber}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}>{po.shipperChName}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.orderLoadingBox1}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.orderPackageNumber}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.orderTotalWeight}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{PP}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{CC}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.totalValue}</Button></Grid>
                          </Table.Cell>
                      </Table.Row>
                  ) 
                  poNumber=poNumber+1
              });
              // 根據目前的狀態，我來決定到底要不要更新畫面
              if(this.state.orderTaxWay=='withTax'){
                this.setState({
                  todayWithTaxPO:todayWithTaxPO,
                  todayWithTaxPOContent:todayWithTaxPOContent,
                  printWithTaxPO:printWithTaxPO,
                  todayPO:todayWithTaxPO,
                  printPO:printWithTaxPO,
                  todayPOContent:todayWithTaxPOContent,
                });
                
              }else{
                this.setState({
                  todayWithTaxPO:todayWithTaxPO,
                  todayWithTaxPOContent:todayWithTaxPOContent,
                  printWithTaxPO:printWithTaxPO,
                });
              }
          }
    });

    ref.child('/'+this.state.customerNumber+'/withoutTax/'+year+'/'+month+'/'+day).on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()){
              let todayWithoutTaxPO=snapshot.val();
              this.setState({todayWithoutTaxPO:todayWithoutTaxPO});
              let printWithoutTaxPO=[]
              //下面這個是在準備今日訂單的顯示介面
              var todayWithoutTaxPOContent = []; 
              var poNumber=1
              Object.keys(todayWithoutTaxPO).forEach((key)=> {
                  printWithoutTaxPO.push(key);
                  var po=todayWithoutTaxPO[key];
                  //都大寫
                  var PP=''
                  var CC=''
                  if(po.orderPayWay=='PP'){
                    PP=po.orderTotalFee
                    CC=0
                  }else{
                    CC=po.orderTotalFee
                    PP=0
                  }
                  todayWithoutTaxPOContent.push(
                      <Table.Row  style={{'height':44 }}>
                          <Table.Cell>
                          　　<Grid centered  style={{'padding-bottom':'0.8em' }}> <Checkbox defaultChecked id={po.orderDeliveryNumber} onClick={this.printPOChange}/></Grid>
                          </Table.Cell>
                          <Table.Cell >
                              <Grid centered style={{'padding-top':'0.8em' }}>{poNumber}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}>{po.orderDeliveryNumber}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}>{po.shipperChName}</Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.orderLoadingBox1}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.orderPackageNumber}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.orderTotalWeight}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{PP}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{CC}</Button></Grid>
                          </Table.Cell>
                          <Table.Cell>
                              <Grid centered style={{'padding-top':'0.8em' }}><Button basic  style={{'font-size':'12px'}} name={po.orderDeliveryNumber} onClick={this.poRefineClick}>{po.totalValue}</Button></Grid>
                          </Table.Cell>
                      </Table.Row>
                  ) 
                  poNumber=poNumber+1
              });
              
              // 根據目前的狀態，我來決定到底要不要更新畫面
              if(this.state.orderTaxWay=='withoutTax'){
                this.setState({
                  todayWithoutTaxPO:todayWithoutTaxPO,
                  todayWithoutTaxPOContent:todayWithoutTaxPOContent,
                  printWithoutTaxPO:printWithoutTaxPO,
                  todayPO:todayWithoutTaxPO,
                  printPO:printWithoutTaxPO,
                  todayPOContent:todayWithoutTaxPOContent,
                });
              }else{
                this.setState({
                  todayWithoutTaxPO:todayWithoutTaxPO,
                  todayWithoutTaxPOContent:todayWithoutTaxPOContent,
                  printWithoutTaxPO:printWithoutTaxPO,
                });
              } 
          }else{
            this.setState({
              todayPOContent:[],
            });
          }
    });


    //把暫存檔資料導進來，等一下查詢用
    ref.child('/'+this.state.customerNumber+'/withTax/temp').on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()){
              let temp=snapshot.val();
              var dataOnScreen=[]
              Object.keys(temp).forEach((key)=> {
                var po=temp[key];
                dataOnScreen.push(
                  <Table.Row >                            
                      <Table.Cell >
                          <Grid centered style={{'padding-top':'0.3em' }}>{po.orderDeliveryNumber}</Grid>
                      </Table.Cell>
                      <Table.Cell>
                          <Grid centered style={{'padding-top':'0.3em' }}>{po.shipperChName}</Grid>
                      </Table.Cell>
                                         
                  </Table.Row>
                )
              })
              if(this.state.orderTaxWay=='withTax'){
                this.setState({
                  tempDataWithTaxOnScreen:dataOnScreen,
                  tempDataOnScreen:dataOnScreen,
                });
              }else{
                this.setState({
                  tempDataWithTaxOnScreen:dataOnScreen,
                });
              }
          }else{
            this.setState({
              tempDataOnScreen:[],
            });
          }
    });

    ref.child('/'+this.state.customerNumber+'/withoutTax/temp').on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()){
              let temp=snapshot.val();
              var dataOnScreen=[]
              Object.keys(temp).forEach((key)=> {
                var po=temp[key];
                dataOnScreen.push(
                  <Table.Row >                            
                      <Table.Cell >
                          <Grid centered style={{'padding-top':'0.3em' }}>{po.orderDeliveryNumber}</Grid>
                      </Table.Cell>
                      <Table.Cell>
                          <Grid centered style={{'padding-top':'0.3em' }}>{po.shipperChName}</Grid>
                      </Table.Cell>
                                         
                  </Table.Row>
                )
              })
              if(this.state.orderTaxWay=='withoutTax'){
                this.setState({
                  tempDataWithoutTaxOnScreen:dataOnScreen,
                  tempDataOnScreen:dataOnScreen,
                });
              }else{
                this.setState({
                  tempDataWithoutTaxOnScreen:dataOnScreen,
                });
              }
          }else{
            this.setState({
              tempDataOnScreen:[],
            });
          }
    });

    // Shipper是寄件人
    //把Shipper資料導進來，等一下查詢用
    ref.child('/'+this.state.customerNumber+'/shipper').on('value', (snapshot)=> {
        //先把database值拿出來    
            if(snapshot.val()==null){
            
            }else{
                let shipper=snapshot.val();
                this.setState({'shipperDatabase':shipper});
                //console.log(this.state.shipperDatabase)
            }
    });

    // consignee是收件人
    //把consignee資料導進來，等一下查詢用
    ref.child('/'+this.state.customerNumber+'/consignee').on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()==null){
              this.setState({'cogDatabase':''});
          }else{
              let cog=snapshot.val();
              this.setState({'cogDatabase':cog});
              //console.log(cog)
          }
    });
    

    //把歷史快遞單號資料弄進來
    ref.child('/'+this.state.customerNumber+'/orderDeliveryNumber').on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()==null){
              this.setState({'orderDeliveryNumberDatabase':''});
          }else{
              let data=snapshot.val();
              this.setState({'orderDeliveryNumberDatabase':data});
              //console.log(data)
          }
    });


  }
  
  //單純把輸入的資料導出來，都用這一個，大家都可以用
  handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
    //console.log(this.state)
  }
  
  handleShipperPhoneChange(e){
    this.setState({ [e.target.name]: e.target.value });
    var phone= e.target.value;
    var obj=this.state.shipperDatabase
    
    //下面這個寫法可以確認key值是否存在
    if(obj.hasOwnProperty(phone)){
        //記住，如果你現在要呼叫的是一個變數名稱，不是string的時候，你要放在[]裡面
        var target=obj[phone]
        this.setState({ 
            'shipperPhoneExtNumber':target.shipperPhoneExtNumber, 
            'shipperContact':target.shipperContact,
            'shipperAccount':target.shipperAccount,
            'shipperChName':target.shipperChName,
            'shipperEngName':target.shipperEngName,
            'shipperChAddress':target.shipperChAddress,
            'shipperEngAddress':target.shipperEngAddress,
            
        }); 
        
        /*
        ref.child('/IDService/consignee/'+phone).on('value', (snapshot)=> {
            //先把database值拿出來    
                if(snapshot.val()==null){
                    this.setState({'cogDatabase':''});
                }else{
                    let cog=snapshot.val();
                    this.setState({'cogDatabase':cog});
                    //console.log(this.state.cogDatabase)
                }
            }   
        );
        */

    }else{
        //因為他如果前面找到類似的，就會填入，要把它消掉．
        this.setState({ 
            'shipperPhoneExtNumber':'', 
            'shipperContact':'',
            'shipperAccount':'',
            'shipperChName':'',
            'shipperEngName':'',
            'shipperChAddress':'',
            'shipperEngAddress':'',
            
        });
    }
    
  }

  //材積重的計算，只要解決小數問題，和最後位數顯示的問題就好
  volumeWeightCalculate(){   
    var boxNumber=this.state.pcBoxSize;
    var orderVolumeWeight=0
    var sizeNote=''
    for(var i=0;i<boxNumber;i++){
       var box=i+1
       var L=this.state['LBox'+box]
       var W=this.state['WBox'+box]
       var H=this.state['HBox'+box]
       var pc=this.state['pcBox'+box]
       if(!isNaN(L) && !isNaN(W) && !isNaN(H)&& !isNaN(pc)){
      // 這裡算材積重的時候，已經考慮了所有的箱數
       var weight=FloatDiv(FloatMul(FloatMul(FloatMul(L,W),H),Number(pc)),6000)
       }
     
       orderVolumeWeight=orderVolumeWeight+weight

       // 將尺寸轉換為字符串並添加到 sizeInfo
       var sizeStr = `${L}*${W}*${H}*${pc}`;
       sizeNote += `${sizeStr}\\`;
    }
    
    // 去除sizeNote的最後一個斜槓
    sizeNote = sizeNote.slice(0, -1)
    sizeNote += `\n`
    orderVolumeWeight=Math.round(orderVolumeWeight*100) / 100
    // 我要把材積重，也加到備注當中
    sizeNote += `V.W.${orderVolumeWeight}KG`;
    var orderTotalWeight=this.state.orderTotalWeight;
    var price=Number(this.state.orderChargeFee)+Number(this.state.orderChargeExtFee)
    var orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
    this.setState({ 
        orderVolumeWeight: orderVolumeWeight,
        orderTotalFee: orderTotalFee,
        sizeNote:sizeNote
     });


     //console.log(this.state)

  }

  // 這是紀錄箱子相關的長寬高尺寸資料
  addPcBoxSize(){
    var number=this.state.pcBoxSize
    number=number+1
    this.setState({
      pcBoxSize:number,
      ['pcBox'+number]:'',
      ['LBox'+number]:'',
      ['WBox'+number]:'',
      ['HBox'+number]:'',
    })
  }

  cutPcBoxSize(){
    var number=this.state.pcBoxSize
    if(number>1){
      delete this.state['pcBox'+number]
      delete this.state['LBox'+number]
      delete this.state['WBox'+number]
      delete this.state['HBox'+number]
      
      
      var pcBoxSize=number-1;
      var pcBoxTotal=0;
      for(var i=0;i<pcBoxSize;i++){
        var boxNumber=i+1
        pcBoxTotal=Number(pcBoxTotal)+Number(this.state['pcBox'+boxNumber])
      }
      
      if(pcBoxTotal==this.state.orderPackageNumber){
        this.setState({ 
          pcBoxTotal:pcBoxTotal,
          pcBoxSize:pcBoxSize,
          boxOrigialNumber:'green',
          boxAlreadySign:'green'
        });
      }else{
        this.setState({ 
          pcBoxTotal:pcBoxTotal,
          pcBoxSize:pcBoxSize,
          boxOrigialNumber:'red',
          boxAlreadySign:'yellow'
        });
      }

    }
    
  }

  pcBoxCalculate(e){
    this.setState({ [e.target.name]: e.target.value });
    var pcBoxSize=this.state.pcBoxSize;
    var number= e.target.name.substring(5)
    var pcBoxTotal=0;
    for(var i=0;i<pcBoxSize;i++){
      var boxNumber=i+1
      var PCs=0 
      if (boxNumber==number){
        //這一個是因為剛輸入的這一格沒有辦法馬上呼叫到state,所以要特別做
        var PCs=e.target.value 
        //console.log(PCs)
       }else{
        var PCs=this.state['pcBox'+boxNumber]
        //console.log(PCs)
       }

       pcBoxTotal=Number(pcBoxTotal)+Number(PCs)
    }
    
    if(pcBoxTotal==this.state.orderPackageNumber){
      this.setState({ 
        pcBoxTotal:pcBoxTotal,
        boxOrigialNumber:'green',
        boxAlreadySign:'green'
      });
    }else{
      this.setState({ 
        pcBoxTotal :  pcBoxTotal,
        boxOrigialNumber:'red',
        boxAlreadySign:'yellow'
      });
    }
       
  }
  
  //總重輸入以後，我要計算運費
  orderTotalWeight(e){
    this.setState({ [e.target.name]: e.target.value });
    var orderTotalWeight=e.target.value;
    var price=Number(this.state.orderChargeFee)+Number(this.state.orderChargeExtFee)
    var orderVolumeWeight=this.state.orderVolumeWeight
    var orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
    this.setState({ orderTotalFee: orderTotalFee });
  }
  
  //點擊選擇的輸入方式，要特別處理
  orderTaxWay(e,{ value }){
    if(value=='withTax'){
      this.setState({
        'orderTaxWay':value,
        todayPO:this.state.todayWithTaxPO,
        printPO:this.state.printWithTaxPO,
        todayPOContent:this.state.todayWithTaxPOContent,
        tempDataOnScreen:this.state.tempDataWithTaxOnScreen,
      })  
    }else{
      this.setState({
        'orderTaxWay':value,
        todayPO:this.state.todayWithoutTaxPO,
        printPO:this.state.printWithoutTaxPO,
        todayPOContent:this.state.todayWithoutTaxPOContent,
        tempDataOnScreen:this.state.tempDataWithoutTaxOnScreen,
      })  
    }
    
  }
  //點擊選擇的輸入方式，要特別處理
  orderPassWay(e,{ value }){
    if(value=='PIBK'){
      var orderTotalWeight=this.state.orderTotalWeight;
      var orderVolumeWeight=this.state.orderVolumeWeight;
      var price=300+Number(this.state.orderChargeExtFee)
      var orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
      this.setState({
        orderPassWay:value,
        orderChargeFee:300,
        orderTotalFee: orderTotalFee 
      }) 
    }else{
      var orderTotalWeight=this.state.orderTotalWeight;
      var orderVolumeWeight=this.state.orderVolumeWeight;
      var price=250+Number(this.state.orderChargeExtFee)
      var orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
      this.setState({
        orderPassWay:value,
        orderChargeFee:250,
        orderTotalFee: orderTotalFee 
      }) 
    }
     
  }
  //點擊選擇的輸入方式，要特別處理
  orderPayWay(e,{ value }){
    this.setState({
        'orderPayWay':value,
      })  
  }

  customsClearanceChange(e,{ value }){

    this.setState({
      'shipperCustomsClearance':value,
    })

  }

  //下面是要特別處理，把箱數位數弄成三位數
  handleBoxInputChange(e){
    var value=e.target.value;
    function digits_count(n) {
        var count = 0;
        if (n >= 1) ++count;
      
        while (n / 10 >= 1) {
          n /= 10;
          ++count;
        }

        return count;
    }

    var digit=digits_count(value)
    if(digit==1){
      //value='00'+value
    }else{
        if(digit==2){
            //value='0'+value
        }
    }
    //console.log(value)
    this.setState({ [e.target.name]: value });
  }

  //計算運費總價
  orderChargeFee(e){
    this.setState({ [e.target.name]: e.target.value });
    var price= e.target.value; 

    var numericPrice = parseFloat(price.trim()) || 0;
    var currency = (numericPrice > 100) ? 'NTD' : 'USD';
    this.setState({ currency: currency });

    if(!isNaN(this.state.orderChargeExtFee)){
      price=Number(price)+Number(this.state.orderChargeExtFee)
    }
    
    var orderTotalWeight=this.state.orderTotalWeight
    var orderVolumeWeight=this.state.orderVolumeWeight
    var orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
   
    this.setState({ orderTotalFee: orderTotalFee });
  }

  //計算運費總價-每公斤加價的部分
  orderChargeExtFee(e){
    this.setState({ [e.target.name]: e.target.value });
    var price= e.target.value; 

    if(!isNaN(this.state.orderChargeFee)){
      price=Number(price)+Number(this.state.orderChargeFee)
    }
    
    var orderTotalWeight=this.state.orderTotalWeight
    var orderVolumeWeight=this.state.orderVolumeWeight
    var orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
   
    this.setState({ orderTotalFee: orderTotalFee });

  }
  
  //進口方和收件方是不是一樣
  //這個就是那個勾勾的開關
  cogCheck(e){
    this.setState({
        receiverTheSame:!this.state.receiverTheSame,  
    }) 
    //把receiver資料導進來，等一下查詢用
    ref.child('/'+this.state.customerNumber+'/receiver').on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()==null){
              this.setState({'recDatabase':''});
          }else{
              let rec=snapshot.val();
              this.setState({'recDatabase':rec});
          }
    });

  } 
  
  //要提升進口方的輸入速度，當進口方的欄位有改變的時候，這個地方都要更新
  handleCogPhoneChange(e){
    this.setState({ [e.target.name]: e.target.value });

    var phone=this.state.cogPhoneAreaNumber+e.target.value;
    var obj=this.state.cogDatabase
    
    //下面這個寫法可以確認key值是否存在
    if(obj.hasOwnProperty(phone)){
        //記住，如果你現在要呼叫的是一個變數名稱，不是string的時候，你要放在[]裡面
        var target=obj[phone]
        var state={ 
          cogPhoneAreaNumber:target.cogPhoneAreaNumber, 
          cogPhoneExtNumber:target.cogPhoneExtNumber,
          cogContact:target.cogContact,
          cogFaxNumber:target.cogFaxNumber,
          cogEngName:target.cogEngName,
          cogNPWP:target.cogNPWP,
          cogZipCode:target.cogZipCode,
          cogEngAddressRegion:target.cogEngAddressRegion,
          cogEngAddressCity:target.cogEngAddressCity,
          cogEngAddress:target.cogEngAddress,
          orderPassWay:'',
          orderChargeFee:'',
          orderChargeExtFee:'',
          orderTotalFee:''
       }
        //下面這邊是如果帶出舊資料的時候，系統就會重算資料
        if(target.orderPassWay){        
            state.orderPassWay=target.orderPassWay
            state.orderChargeFee=target.orderChargeFee
            state.orderChargeExtFee=target.orderChargeExtFee

            var orderTotalWeight=this.state.orderTotalWeight;
            var price=(Number(target.orderChargeFee)+Number(target.orderChargeExtFee)) 
            var orderVolumeWeight=this.state.orderVolumeWeight
            state.orderTotalFee=CalculateFee(orderTotalWeight,orderVolumeWeight,price)
        }
        this.setState(state);        
    }else{
       //當最後電話輸入完，確認沒有這支電話，那我要把所有欄位恢復空白
        var state={ 
          
          cogPhoneExtNumber:'',
          cogContact:'',
          cogFaxNumber:'',
          cogEngName:'',
          cogNPWP:'',
          cogZipCode:'',
          cogEngAddressRegion:'',
          cogEngAddressCity:'',
          cogEngAddress:'',
          orderPassWay: 'PIBK',
          //下面兩句就是造成運費不會運算的問題
          //orderChargeFee:300,
          //orderTotalFee:300
        }
        this.setState(state); 
    }

  }

  //要提升收件方的輸入速度，當進口方的欄位有改變的時候，這個地方都要更新
  handleRecPhoneChange(e){
    this.setState({ [e.target.name]: e.target.value });
    
    var phone=this.state.recPhoneAreaNumber+e.target.value;
    var obj=this.state.recDatabase
    
    if(obj.hasOwnProperty(phone)){
      
          //記住，如果你現在要呼叫的是一個變數名稱，不是string的時候，你要放在[]裡面
          var target=obj[phone]
          var state={ 
            recPhoneAreaNumber:target.recPhoneAreaNumber, 
            recPhoneExtNumber:target.recPhoneExtNumber,
            recContact:target.recContact,
            recFaxNumber:target.recFaxNumber,
            recEngName:target.recEngName,
            recNPWP:target.recNPWP,
            recZipCode:target.recZipCode,
            recEngAddressRegion:target.recEngAddressRegion,
            recEngAddressCity:target.recEngAddressCity,
            recEngAddress:target.recEngAddress,
        }
        this.setState(state);    
      }else{
        var state={ 
            
            recPhoneExtNumber:'',
            recContact:'',
            recFaxNumber:'',
            recEngName:'',
            recNPWP:'',
            recZipCode:'',
            recEngAddressRegion:'',
            recEngAddressCity:'',
            recEngAddress:'',
        }
        this.setState(state); 
      }

  }

  addItem(){
      var number=this.state.itemPcNumber
      number=number+1
      this.setState({
        'itemPcNumber':number,
        ['itemName'+number]:'',
        ['itemHScode'+number]:'',
        ['itemNumber'+number]:'',
        ['itemUnitPrice'+number]:'',

      })
      //console.log(this.state)

  }

  cutItem(){
    var number=this.state.itemPcNumber
    if(number>1){
      delete this.state['itemName'+number]
      delete this.state['itemHScode'+number]
      delete this.state['itemNumber'+number]
      delete this.state['itemUnitPrice'+number]
      this.setState({
        'itemPcNumber':number-1,
      })

      //刪除品項，要重新計算個數
      var totalItemNumber=this.state.itemPcNumber;
      var totalPCs=0;
      for(var i=0;i<totalItemNumber;i++){
        
        var itemNumber=i+1
        var PCs=0
        if (itemNumber==number){
        //這一個是因為剛輸入的這一格沒有辦法馬上呼叫到state,所以要特別做
        //var PCs=e.target.value 
        //console.log(PCs)
        }else{
        var PCs=this.state['itemNumber'+itemNumber]
        //console.log(PCs)
        }
        
        //console.log(value)     
        totalPCs=Number(totalPCs)+Number(PCs)
        //console.log(totalPCs)
      }

      this.setState({totalPCs : totalPCs});
      
      //刪除品項，要重新計算價格
      var totalValue=0;
      for(var i=0;i<totalItemNumber;i++){
        
        var itemNumber=i+1
        var price=0
        if (itemNumber==number){
          //這一個是因為剛輸入的這一格沒有辦法馬上呼叫到state,所以要特別做
          //var price=e.target.value 
        }else{
          var price=this.state['itemUnitPrice'+itemNumber]
        }
        
        //console.log(value)     
        totalValue=Number(totalValue)+Number(price)
        //console.log(totalValue)
      }

      this.setState( { totalValue : Math.round(totalValue*100) / 100},
      );

    }
    //console.log(this.state)
  }
  
  //因為我要讓數字輸入後就可以有計算結果，所以我要特別處理
  handleTotalPCs(e){
    this.setState({ [e.target.name]: e.target.value });
    //先抓出來現在更新的是哪一格，因為這個一格沒辦法馬上呼叫state，要直接帶進來
    var number= e.target.name.substring(10)
    //console.log(number)
    //確認總共幾箱
    var totalItemNumber=this.state.itemPcNumber;
    var totalPCs=0;
    for(var i=0;i<totalItemNumber;i++){
      
      var itemNumber=i+1
      var PCs=0
      if (itemNumber==number){
       //這一個是因為剛輸入的這一格沒有辦法馬上呼叫到state,所以要特別做
       var PCs=e.target.value 
       //console.log(PCs)
      }else{
       var PCs=this.state['itemNumber'+itemNumber]
       //console.log(PCs)
      }
      
      //console.log(value)     
      totalPCs=Number(totalPCs)+Number(PCs)
      //console.log(totalPCs)
   }
    this.setState({totalPCs : totalPCs});
    
    //console.log(this.state.totalPCs)
  }

  //因為我要讓數字輸入後就可以有計算結果，所以我要特別處理
  handlePriceValueCalculate(e){
     this.setState({ [e.target.name]: e.target.value });
     var number= e.target.name.substring(13)
     //console.log(number)
     var totalItemNumber=this.state.itemPcNumber;
     var totalValue=0;
     for(var i=0;i<totalItemNumber;i++){
       
       var itemNumber=i+1
       var price=0
       if (itemNumber==number){
        //這一個是因為剛輸入的這一格沒有辦法馬上呼叫到state,所以要特別做
        var price=e.target.value 
       }else{
        var price=this.state['itemUnitPrice'+itemNumber]
       }
       
       //console.log(value)     
       totalValue=Number(totalValue)+Number(price)
       //console.log(totalValue)
    }
     this.setState( { totalValue : Math.round(totalValue*100) / 100},
     );
     
     
  }
 
  //存檔好了以後，你要把把對話方塊關掉，就要用這個
  handleSaveFileClose(){
    this.setState( { saveFilefinish : false});
  }

  //資料存進資料庫
  //不管shipper或是Cog存不存在，都存起來
　//firebase　database不能亂存，所以要把存不進去的刪除，你才可以存
  saveFile(){
    
    var orderTaxWay=this.state.orderTaxWay
    var todayPO=this.state.todayPO
    var orderDeliveryNumber=this.state.orderDeliveryNumber
    var orderDeliveryNumberRecord=this.state.orderDeliveryNumberDatabase
    
    //透過status的狀態決定現在要不要還原空白介面，
    //1是正常狀態，恢復空白
    //0是快遞單號重複狀態，不動
    var status=1
  
    //存檔之前，我要先確定有快遞單號，要不然一存檔，會把資料庫刪除
    if(this.state.orderDeliveryNumber!==''){
      //下面這個寫法要確認單號是不是用過了
      var message=''
      if(!orderDeliveryNumberRecord.hasOwnProperty(orderDeliveryNumber.toString())){
            //這個區塊是新增訂單的處理區塊
            //你要非常注意下面這段的寫法，要這句你才可以深複製this.state，然後你接下來對POData的修改才不會影響this.state
            let POData = JSON.parse(JSON.stringify(this.state));
            // 如果進口方和收件方是一樣的，就把進口方的資料複製到收件方
            if(this.state.receiverTheSame==true){
              POData.recPhoneAreaNumber=this.state.cogPhoneAreaNumber
              POData.recPhoneNumber=this.state.cogPhoneNumber
              POData.recPhoneExtNumber=this.state.cogPhoneExtNumber
              POData.recContact=this.state.cogContact
              POData.recFaxNumber=this.state.cogFaxNumber
              POData.recEngName=this.state.cogEngName
              POData.recNPWP=this.state.cogNPWP
              POData.recZipCode=this.state.cogZipCode
              POData.recEngAddressRegion=this.state.cogEngAddressRegion
              POData.recEngAddressCity=this.state.cogEngAddressCity
              POData.recEngAddress=this.state.cogEngAddress  
            }
            
            //因為我是把整個state拿來存，所以裡面跟本單沒有關係的資料，我要先刪除
            
            delete POData['todayPO']
            delete POData['todayWithTaxPO']
            delete POData['todayWithoutTaxPO']

            delete POData['todayPOContent']
            delete POData['todayWithTaxPOContent']
            delete POData['todayWithoutTaxPOContent']

            delete POData['printPO']
            delete POData['printWithTaxPO']
            delete POData['printWithoutTaxPO']

            delete POData['shipperDatabase']
            delete POData['cogDatabase']
            delete POData['orderDeliveryNumberDatabase']
            
            delete POData['tempDataOnScreen']
            delete POData['tempDataWithTaxOnScreen']
            delete POData['tempDataWithoutTaxOnScreen']

            delete POData['cogChangePhoneAreaNumber']
            delete POData['cogChangePhoneNumber']
            delete POData['cogChangePhoneExtNumber']
            delete POData['cogChangeContact']
            delete POData['cogChangeFaxNumber']
            delete POData['cogChangeEngName']
            delete POData['cogchangeNPWP']
            delete POData['cogChangeZipCode']
            delete POData['cogChangeEngAddressRegion']
            delete POData['cogChangeEngAddressCity']
            delete POData['cogChangeEngAddress']

            delete POData['today']
            delete POData['year']
            delete POData['month']
            delete POData['day']

            delete POData['saveFilefinish']
            delete POData['deleteHistoryOpen']
            delete POData['message']

            if(this.state.recDatabase){
              delete POData['recDatabase']
            }

            
            //準備一個object，一次呼叫就好
            var updates = {};

            //把訂單存起來
            // 我要分成兩個資料夾，分別代表的是包稅和不包稅
            updates['/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.orderDeliveryNumber] = POData;
           
            //把單號存起來
            //這個node的資料是為了避免訂單號碼重複，所以我要存在root，不分with tax or without tax
            updates['/'+this.state.customerNumber+'/orderDeliveryNumber/'+this.state.orderDeliveryNumber]=0;
            

            //把訂單日期存起來，後續要查單修改，我才可以知道哪一天
            //這個月的資料會包含上個月的資料，這樣我跨月比較好找
            let date = new Date();
            let nowYear=date.getFullYear()
            let nowMonth= date.getMonth()+1       //month要加1，因為0是一月
            let nextDate = new Date(date.setMonth(date.getMonth()+1));
            let nextYear=nextDate.getFullYear()
            let nextMonth=nextDate.getMonth()+1
            
            //這個月的資料會包含上個月的資料，這樣我跨月比較好找
            //所以我要存在兩個不同的節點，讓with tax和without tax的資料可以分開尋找
            updates['/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+'/poDate/'+nowYear+'/'+nowMonth+'/'+this.state.orderDeliveryNumber]=this.state.today;
            updates['/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+'/poDate/'+nextYear+'/'+nextMonth+'/'+this.state.orderDeliveryNumber]=this.state.today;
            ref.update(updates);



      }else if(orderDeliveryNumberRecord.hasOwnProperty(orderDeliveryNumber.toString()) & todayPO.hasOwnProperty(orderDeliveryNumber.toString()) & this.state.changeTodayPO==true) {
            //這個區塊是修改當天訂單資料的處理區塊
            let POData = JSON.parse(JSON.stringify(this.state));
            if(this.state.receiverTheSame==true){
              POData.recPhoneAreaNumber=this.state.cogPhoneAreaNumber
              POData.recPhoneNumber=this.state.cogPhoneNumber
              POData.recPhoneExtNumber=this.state.cogPhoneExtNumber
              POData.recContact=this.state.cogContact
              POData.recFaxNumber=this.state.cogFaxNumber
              POData.recEngName=this.state.cogEngName
              POData.recNPWP=this.state.cogNPWP
              POData.recZipCode=this.state.cogZipCode
              POData.recEngAddressRegion=this.state.cogEngAddressRegion
              POData.recEngAddressCity=this.state.cogEngAddressCity
              POData.recEngAddress=this.state.cogEngAddress  
            }
            
            //因為我是把整個state拿來存，所以裡面跟本單沒有關係的資料，我要先刪除
            delete POData['todayPO']
            delete POData['todayWithTaxPO']
            delete POData['todayWithoutTaxPO']

            delete POData['todayPOContent']
            delete POData['todayWithTaxPOContent']
            delete POData['todayWithoutTaxPOContent']

            delete POData['printPO']
            delete POData['printWithTaxPO']
            delete POData['printWithoutTaxPO']

            delete POData['shipperDatabase']
            delete POData['cogDatabase']
            delete POData['orderDeliveryNumberDatabase']
            
            delete POData['tempDataOnScreen']
            delete POData['tempDataWithTaxOnScreen']
            delete POData['tempDataWithoutTaxOnScreen']

            delete POData['cogChangePhoneAreaNumber']
            delete POData['cogChangePhoneNumber']
            delete POData['cogChangePhoneExtNumber']
            delete POData['cogChangeContact']
            delete POData['cogChangeFaxNumber']
            delete POData['cogChangeEngName']
            delete POData['cogchangeNPWP']
            delete POData['cogChangeZipCode']
            delete POData['cogChangeEngAddressRegion']
            delete POData['cogChangeEngAddressCity']
            delete POData['cogChangeEngAddress']

            delete POData['today']
            delete POData['year']
            delete POData['month']
            delete POData['day']

            delete POData['saveFilefinish']
            delete POData['deleteHistoryOpen'] 
            delete POData['message']
            
            if(this.state.recDatabase){
              delete POData['recDatabase']
            }

            var updates = {};
            updates['/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.orderDeliveryNumber] = POData;
            ref.update(updates);

            
      }else if(orderDeliveryNumberRecord.hasOwnProperty(orderDeliveryNumber.toString())  & this.state.changeHistoryPO==true) {
        //這裡是修改歷史訂單
        //所以存檔路徑要特別處理
        let POData = JSON.parse(JSON.stringify(this.state));
        if(this.state.receiverTheSame==true){
          POData.recPhoneAreaNumber=this.state.cogPhoneAreaNumber
          POData.recPhoneNumber=this.state.cogPhoneNumber
          POData.recPhoneExtNumber=this.state.cogPhoneExtNumber
          POData.recContact=this.state.cogContact
          POData.recFaxNumber=this.state.cogFaxNumber
          POData.recEngName=this.state.cogEngName
          POData.recNPWP=this.state.cogNPWP
          POData.recZipCode=this.state.cogZipCode
          POData.recEngAddressRegion=this.state.cogEngAddressRegion
          POData.recEngAddressCity=this.state.cogEngAddressCity
          POData.recEngAddress=this.state.cogEngAddress  
        }
        
        //因為我是把整個state拿來存，所以裡面跟本單沒有關係的資料，我要先刪除
            delete POData['todayPO']
            delete POData['todayWithTaxPO']
            delete POData['todayWithoutTaxPO']

            delete POData['todayPOContent']
            delete POData['todayWithTaxPOContent']
            delete POData['todayWithoutTaxPOContent']

            delete POData['printPO']
            delete POData['printWithTaxPO']
            delete POData['printWithoutTaxPO']

            delete POData['shipperDatabase']
            delete POData['cogDatabase']
            delete POData['orderDeliveryNumberDatabase']
            
            delete POData['tempDataOnScreen']
            delete POData['tempDataWithTaxOnScreen']
            delete POData['tempDataWithoutTaxOnScreen']

            delete POData['cogChangePhoneAreaNumber']
            delete POData['cogChangePhoneNumber']
            delete POData['cogChangePhoneExtNumber']
            delete POData['cogChangeContact']
            delete POData['cogChangeFaxNumber']
            delete POData['cogChangeEngName']
            delete POData['cogchangeNPWP']
            delete POData['cogChangeZipCode']
            delete POData['cogChangeEngAddressRegion']
            delete POData['cogChangeEngAddressCity']
            delete POData['cogChangeEngAddress']

            delete POData['today']
            delete POData['year']
            delete POData['month']
            delete POData['day']

            delete POData['saveFilefinish']
            delete POData['deleteHistoryOpen'] 
            delete POData['message'] 

            delete POData['historyRecordStart'] 
            delete POData['historyRecordEnd'] 
            delete POData['historyErrorMessage'] 
        
        if(this.state.recDatabase){
          delete POData['recDatabase']
        }
        

        //所以存檔路徑要特別處理
        // historyRecordChangePath就是訂單日期
        let path=this.state.historyRecordChangePath
        let year=path.slice(0, 4)
        let month=path.slice(4, 6)
        let day=path.slice(6, 8)

        var updates = {};
        updates['/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+year+'/'+month+'/'+day+'/'+this.state.orderDeliveryNumber] = POData;
        ref.update(updates);

        
      }else{
            //顯示快遞單號重複了
            status=0
      }
     
    }else{
      this.setState({message:'你要輸入快遞單號喔!'})
      return   
    }
   
    //console.log(this.state.message)

    /////////////////////////////////////////////////////////////////////////////////
    //以下我們要把相關的通訊資料存起來，會存在root，不分with tax or without tax
    //原本要先確認資料有沒有變動，但是現在先不管，因為流量不大，先每次更新
    //這個部分我們要處理shipper的部分
    var shipperData={
        'shipperPhoneExtNumber':this.state.shipperPhoneExtNumber, 
        'shipperContact':this.state.shipperContact,
        'shipperAccount':this.state.shipperAccount,
        'shipperChName':this.state.shipperChName,
        'shipperEngName':this.state.shipperEngName,
        'shipperChAddress':this.state.shipperChAddress,
        'shipperEngAddress':this.state.shipperEngAddress,
    }
    IDSaveShipper(this.state.shipperPhoneNumber,shipperData)
    
    var cogData={
        'cogPhoneAreaNumber':this.state.cogPhoneAreaNumber, 
        'cogPhoneNumber':this.state.cogPhoneNumber,
        'cogPhoneExtNumber':this.state.cogPhoneExtNumber,
        'cogContact':this.state.cogContact,
        'cogFaxNumber':this.state.cogFaxNumber,
        'cogEngName':this.state.cogEngName,
        'cogNPWP':this.state.cogNPWP,
        'cogZipCode':this.state.cogZipCode,
        'cogEngAddressRegion':this.state.cogEngAddressRegion,
        'cogEngAddressCity':this.state.cogEngAddressCity,
        'cogEngAddress':this.state.cogEngAddress,
        'orderPassWay':this.state.orderPassWay,
        'orderChargeFee':this.state.orderChargeFee,
        'orderChargeExtFee':this.state.orderChargeExtFee
    }
    IDSaveCog(this.state.cogPhoneAreaNumber,this.state.cogPhoneNumber,cogData)
    
    if(this.state.receiverTheSame==false){
      var recData={
        recPhoneAreaNumber:this.state.recPhoneAreaNumber,
        recPhoneNumber:this.state.recPhoneNumber,
        recPhoneExtNumber:this.state.recPhoneExtNumber,
        recContact:this.state.recContact,
        recFaxNumber:this.state.recFaxNumber,
        recEngName:this.state.recEngName,
        recNPWP:this.state.recNPWP,
        recZipCode:this.state.recZipCode,
        recEngAddressRegion:this.state.recEngAddressRegion,
        recEngAddressCity:this.state.recEngAddressCity,
        recEngAddress:this.state.recEngAddress,
    }

    IDSaveRec(this.state.recPhoneAreaNumber,this.state.recPhoneNumber,recData)

    }
    
    
    var state={
            receiverTheSame: true,
            orderPassWay: 'PIBK',
            orderPayWay: 'PP',
            itemPcNumber:1,
            
            totalValue:0,
            saveFilefinish:true,
            message:'',
            
            shipperCustomsClearance:'',
            shipperPhoneNumber:'',
            shipperPhoneExtNumber:'', 
            shipperContact:'',
            shipperAccount:'',
            shipperChName:'',
            shipperEngName:'',
            shipperChAddress:'',
            shipperEngAddress:'',

            orderDeliveryNumber:'',
            orderPackageNumber:'',
            orderTotalWeight:'',
            orderVolumeWeight:'',
            orderLoadingBox1:'',
            orderLoadingBox2:'',
            
            orderTotalFee:'',
            orderNote:'',
            sizeNote:'',
            LBox1:'',
            WBox1:'',
            HBox1:'',
            pcBoxTotal:0,
            pcBoxSize:1,
            boxOrigialNumber:'red',
            boxAlreadySign:'yellow',

            cogPhoneAreaNumber:'', 
            cogPhoneNumber:'',
            cogPhoneExtNumber:'',
            cogContact:'',
            cogFaxNumber:'',
            cogEngName:'',
            cogNPWP:'',
            cogZipCode:'',
            cogEngAddressRegion:'',
            cogEngAddressCity:'',
            cogEngAddress:'',
            orderChargeFee:300,
            orderChargeExtFee:'',
            orderTotalFee:300,
            currency:'NTD',

            recPhoneAreaNumber:'',
            recPhoneNumber:'',
            recPhoneExtNumber:'',
            recContact:'',
            recFaxNumber:'',
            recEngName:'',
            recNPWP:'',
            recZipCode:'',
            recEngAddressRegion:'',
            recEngAddressCity:'',
            recEngAddress:'',

            itemName1:'',
            itemHScode1:'',
            itemNumber1:'',
            itemUnitPrice1:'',
            totalPCs:0,
            
            //下面這是為了嚴格控管單號重複的訊號
            changeTodayPO: false,
            changeHistoryPO:false,



    };

    for(var i=1; i<this.state.orderPackageNumber;i++){
        var boxNumber=i+1
        delete this.state['LBox'+boxNumber]
        delete this.state['WBox'+boxNumber]
        delete this.state['HBox'+boxNumber]

    }

    for(var i=1; i<this.state.itemPcNumber;i++){
        var number=i+1
        delete this.state['itemName'+number]
        delete this.state['itemHScode'+number]
        delete this.state['itemNumber'+number]
        delete this.state['itemUnitPrice'+number]
    }
    
    //如果一切順利，單據已經存入資料庫，那就是Status1，那就畫面歸零
    //如果單號重複，那就跳出警示訊息
    if(status==1){
       this.setState(state);
       
    }else if(status==0){
       this.setState({message:'快遞單號重複了喔!'})
    }
    
    /* 下面的部分可能可以刪掉了
    因為目前不需要這樣的設計了
    ref.child('/IDService/shipper').on('value', (snapshot)=> {
        //先把database值拿出來    
            if(snapshot.val()==null){
            
            }else{
                let shipper=snapshot.val();
                this.setState({'shipperDatabase':shipper});
                //console.log(this.state.shipperDatabase)
            }
    });
   
    //把consignee資料導進來，等一下查詢用
    ref.child('/IDService/consignee').on('value', (snapshot)=> {
      //先把database值拿出來    
          if(snapshot.val()==null){
              this.setState({'cogDatabase':''});
          }else{
              let cog=snapshot.val();
              this.setState({'cogDatabase':cog});
              //console.log(this.state.cogDatabase)
          }
    });
    */
    

    
    
  }

  //把挑到的訂單存起來
  printPOChange(e){
      var PO=this.state.printPO;
      if(PO.includes(e.target.id)){
        const index = PO.indexOf(e.target.id);
        if (index > -1) {
          PO.splice(index, 1);
        }
        this.setState({printPO:PO})
      }else{
        PO.push(e.target.id);
        this.setState({printPO:PO})
      } 
      
      //console.log(this.state.printPO)
  }
  
  //發現今天key單的內容錯誤，要改今天的key單資料
  poRefineClick(e){
    var key=e.target.name;
    var po=this.state.todayPO[key];
    // 下面的這句話就會把整個state蓋掉，所有資料都變成現在這張po的state
    this.setState(po);
    this.setState({
      //我要透過下面的訊號知道目前的處理狀態是不是改歷史資料，如果是，那快遞單號重複會給你改，如果不是，一樣不能存
      changeTodayPO: true
    })
    
  }

  //這是要把Excel檔案導出來
  exportFile(){
    var allPO=this.state.todayPO;
    var printPO= this.state.printPO;
    var today=this.state.today
    exportExcelFile(allPO,printPO,today, this.state.orderTaxWay)            
  }

  labelPrint(printPO,allPO,fileName){
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //把印製的Label格式準備好
    let LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    printPO.sort()
    var last=printPO[printPO.length-1];
    
    // 這裡我要區分成兩種不同的標籤方式
    if(this.state.orderTaxWay=='withTax'){
        //開始處理每張訂單，導進標籤印製群組
        printPO.forEach(function(item){
          var target=allPO[item];
          
          //logo是跨境通的logo，在這裡先導進來
          // var logo=LogoImg();

          //下面這句是避免firebase導入格式不乾淨，全部轉成Number後面好處理
          var LabelNumber=Number(target.orderPackageNumber);
          
          //新加坡的標籤數需求就是總箱數再加一張，就這麼簡單
          for(var i=0;i<LabelNumber;i++){
                //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
                if(target.orderDeliveryNumber===last && i==(LabelNumber-1)){         
                  let Label=lastLabel(target,false)

                  LabelArr.push(Label);
                }else{
                //一般情況下都是跑下面這張標籤        
                  let Label=normalLabel(target,false)

                  LabelArr.push(Label);
                };
              };

        });
    }else{
        //開始處理每張訂單，導進標籤印製群組
        printPO.forEach(function(item){
          var target=allPO[item];
          

          //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
          var LabelNumber=Number(target.orderPackageNumber);
          
          
          
          //新加坡的標籤數需求就是總箱數再加一張，就這麼簡單
          for(var i=0;i<LabelNumber;i++){
                //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
                if(target.orderDeliveryNumber===last && i==(LabelNumber-1)){         
                  let Label=lastLabel(target,true);

                  LabelArr.push(Label);

                }else{
                //一般情況下都是跑下面這張標籤        
                  let Label=normalLabel(target,true);

                  LabelArr.push(Label);
                };
              };

        });
    }
 
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 8
        } ,
        styles: {
          head: {           
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 283},
        
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      pdfmake.createPdf(docDefinition).download(fileName);

}


  //這是要把標籤印出來
  handleLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改
    let printPO= this.state.printPO;
    let allPO=this.state.todayPO;
    let fileName= this.state.today+"跨境通印尼標籤"+'.pdf'
    
    this.labelPrint(printPO,allPO,fileName)
  }

  //刪除今天key錯的訂單
  //需要刪除兩個地方，一個是訂單資料，一個是訂單號碼
  deleteHistory(){
    if(this.state.deleteHistory!==''){
      ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deleteHistory).remove()
      ref.child('/'+this.state.customerNumber+'/orderDeliveryNumber/'+this.state.deleteHistory).remove()
    }else{
      
    }
    
    this.setState({
      deleteHistoryOpen:false,
      deleteHistory:''
    })
    
  }
  
  //這是要把歷史Excel檔案導出來
  historyExportFile(){
    var start= this.state.historyRecordStart
    var startYear=start.slice(0, 4)
    var startMonth=start.slice(4, 6)
    var startDay=start.slice(6, 8)

    function getLastDayOfMonth(dateString) {
      // 解析日期字符串
      const year = parseInt(dateString.substr(0, 4), 10);
      const month = parseInt(dateString.substr(4, 2), 10);
    
      // 创建一个新的日期对象，将日期设置为下个月的第0天
      const nextMonthDate = new Date(year, month, 0);
    
      // 获取下个月的最后一天
      const lastDay = nextMonthDate.getDate();
    
      return lastDay;
    }
    
    // if the end date is empty, then we use the start date
    if (!this.state.historyRecordEnd){
      var targetDay=start
      ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+startYear+'/'+startMonth+'/'+startDay).on('value', (snapshot)=> {
        //先把database值拿出來    
            if(snapshot.val()==null){
              this.setState({historyErrorMessage:'找不到資料'})
            }else{
              var allPO=snapshot.val()
              var printPO= Object.keys(allPO);
              exportExcelFile(allPO,printPO,targetDay,this.state.orderTaxWay)  
              this.setState({
                historyRecordStart:'',
                historyErrorMessage:''
              })        
            }
            
      })
    }else{
      var end= this.state.historyRecordEnd
      var endYear=end.slice(0, 4)
      var endMonth=end.slice(4, 6)
      var endDay=end.slice(6, 8)
      var targetDay=start+'-'+end

      var allPO={}
      if (startMonth==endMonth){
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+startYear+'/'+startMonth+'/').orderByKey().startAt(startDay).endAt(endDay).on('value', (snapshot)=> {
          //先把database值拿出來    
              if(snapshot.val()==null){
                this.setState({historyErrorMessage:'找不到資料'})
              }else{
                var val=snapshot.val()
                for(var key in val){
                  let temp=val[key]
                  for(var key2 in temp){
                    allPO[key2]=temp[key2]
                  }
                }
                var printPO= Object.keys(allPO);
                exportExcelFile(allPO,printPO,targetDay,this.state.orderTaxWay) 
                this.setState({
                  historyRecordStart:'',
                  historyRecordEnd:'',
                  historyErrorMessage:''
                })         
              }
              
        })

      }else{
        var lastDay=getLastDayOfMonth(start)
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+startYear+'/'+startMonth+'/').orderByKey().startAt(startDay).endAt(lastDay.toString()).once('value', (snapshot)=> {
          //先把database值拿出來    
              if(snapshot.val()==null){
                
              }else{
                var val=snapshot.val()
                for(var key in val){
                  let temp=val[key]
                  for(var key2 in temp){
                    allPO[key2]=temp[key2]
                  }
                }      
              }
        }).then(()=>{
          ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+endYear+'/'+endMonth+'/').orderByKey().startAt('1').endAt(endDay).once('value', (snapshot)=> {
            //先把database值拿出來    
                if(snapshot.val()==null){
                  
                }else{
                  var val=snapshot.val()
                  for(var key in val){
                    let temp=val[key]
                    for(var key2 in temp){
                      allPO[key2]=temp[key2]
                    }
                  }    
                  
                }
                
          }).then(()=>{
                    var printPO= Object.keys(allPO);
                    if(printPO.length == 0){
                        this.setState({historyErrorMessage:'找不到資料'})
                    }else{
                         exportExcelFile(allPO,printPO,targetDay,this.state.orderTaxWay)   

                        this.setState({
                          historyRecordStart:'',
                          historyRecordEnd:'',
                          historyErrorMessage:''
                        })
                    }
                    
            })
        })
       

      }
    }
  }

  //這是要把歷史Excel檔案導出來
  historyExportLabel(){
    var start= this.state.historyRecordStart
    var startYear=start.slice(0, 4)
    var startMonth=start.slice(4, 6)
    var startDay=start.slice(6, 8)

    function getLastDayOfMonth(dateString) {
      // 解析日期字符串
      const year = parseInt(dateString.substr(0, 4), 10);
      const month = parseInt(dateString.substr(4, 2), 10);
    
      // 创建一个新的日期对象，将日期设置为下个月的第0天
      const nextMonthDate = new Date(year, month, 0);
    
      // 获取下个月的最后一天
      const lastDay = nextMonthDate.getDate();
    
      return lastDay;
    }
    
    // if the end date is empty, then we use the start date
    if (!this.state.historyRecordEnd){
      let targetDay=start
      let fileName=targetDay+'-跨境通歷史印尼標籤-'+this.state.orderTaxWay+'.pdf'
      ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+startYear+'/'+startMonth+'/'+startDay).on('value', (snapshot)=> {
        //先把database值拿出來    
            if(snapshot.val()==null){
              this.setState({historyErrorMessage:'找不到資料'})
            }else{
              var allPO=snapshot.val()
              var printPO= Object.keys(allPO);
              this.labelPrint(printPO,allPO,fileName) 
              this.setState({
                historyRecordStart:'',
                historyErrorMessage:''
              })        
            }
            
      })
    }else{
      let end= this.state.historyRecordEnd
      let endYear=end.slice(0, 4)
      let endMonth=end.slice(4, 6)
      let endDay=end.slice(6, 8)
      let targetDay=start+'-'+end
      let fileName=targetDay+'-跨境通歷史印尼標籤-'+this.state.orderTaxWay+'.pdf'

      var allPO={}
      if (startMonth==endMonth){
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+startYear+'/'+startMonth+'/').orderByKey().startAt(startDay).endAt(endDay).on('value', (snapshot)=> {
          //先把database值拿出來    
              if(snapshot.val()==null){
                this.setState({historyErrorMessage:'找不到資料'})
              }else{
                var val=snapshot.val()
                for(var key in val){
                  let temp=val[key]
                  for(var key2 in temp){
                    allPO[key2]=temp[key2]
                  }
                }
                var printPO= Object.keys(allPO);
                this.labelPrint(printPO,allPO,fileName)
                this.setState({
                  historyRecordStart:'',
                  historyRecordEnd:'',
                  historyErrorMessage:''
                })         
              }
              
        })

      }else{
        let lastDay=getLastDayOfMonth(start)
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+startYear+'/'+startMonth+'/').orderByKey().startAt(startDay).endAt(lastDay.toString()).once('value', (snapshot)=> {
          //先把database值拿出來    
              if(snapshot.val()==null){
                
              }else{
                var val=snapshot.val()
                for(var key in val){
                  let temp=val[key]
                  for(var key2 in temp){
                    allPO[key2]=temp[key2]
                  }
                }      
              }
        }).then(()=>{
          ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+endYear+'/'+endMonth+'/').orderByKey().startAt('1').endAt(endDay).once('value', (snapshot)=> {
            //先把database值拿出來    
                if(snapshot.val()==null){
                  
                }else{
                  var val=snapshot.val()
                  for(var key in val){
                    let temp=val[key]
                    for(var key2 in temp){
                      allPO[key2]=temp[key2]
                    }
                  }    
                  
                }
                
          }).then(()=>{
                    var printPO= Object.keys(allPO);
                    if(printPO.length == 0){
                        this.setState({historyErrorMessage:'找不到資料'})
                    }else{
                        this.labelPrint(printPO,allPO,fileName)  

                        this.setState({
                          historyRecordStart:'',
                          historyRecordEnd:'',
                          historyErrorMessage:''
                        })
                    }
                    
            })
        })
       

      }
    }
  }

 
  //把訂單存入暫存資料夾
  orderToTemp(){
    ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.tempTarget).once('value', (snapshot)=> {
      if(snapshot.val()){
        var target=snapshot.val()
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/temp/'+this.state.tempTarget).set(target)
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.tempTarget).set({})
        this.setState({
          tempTarget:''
        })
      }
    })
  }

  //把暫存訂單送回出貨資料夾
  tempToOrder(){
    ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/temp/'+this.state.tempTarget).once('value', (snapshot)=> {
      if(snapshot.val()){
        var target=snapshot.val()
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.tempTarget).set(target)
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/temp/'+this.state.tempTarget).set({})
        this.setState({
          tempTarget:''
        })
      }
    })
  }

  //把暫存資料夾訂單刪除
  deleteFromTemp(){
    ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/temp/'+this.state.tempTarget).once('value', (snapshot)=> {
      if(snapshot.val()){
        ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/temp/'+this.state.tempTarget).set({})
        this.setState({
          tempTarget:''
        })
      }
    })

  }


  //這是要把進口方的聯絡檔案導出來
  searchCogData(){
    var cogSearchData= this.state.cogSearchData
    var obj=this.state.cogDatabase
    
    if(obj.hasOwnProperty(cogSearchData)){
      
          //記住，如果你現在要呼叫的是一個變數名稱，不是string的時候，你要放在[]裡面
          var target=obj[cogSearchData]
          var state={ 
            cogChangePhoneAreaNumber:target.cogPhoneAreaNumber, 
            cogChangePhoneNumber:target.cogPhoneNumber,
            cogChangePhoneExtNumber:target.cogPhoneExtNumber,
            cogChangeContact:target.cogContact,
            cogChangeFaxNumber:target.cogFaxNumber,
            cogChangeEngName:target.cogEngName,
            cogChangeNPWP:target.cogNPWP,
            cogChangeZipCode:target.cogZipCode,
            cogChangeEngAddressRegion:target.cogEngAddressRegion,
            cogChangeEngAddressCity:target.cogEngAddressCity,
            cogChangeEngAddress:target.cogEngAddress,
            //搜尋資料歸零
            cogSearchData:''
            
          }
        this.setState(state);    
      }else{
        this.setState({cogChangemessage:'找不到這個客人!'}) 
      }

    
    
  }


   //這是要把進口方更改後的資料存起來
   cogDataChangeSave(){
    
    if(this.state.cogChangePhoneAreaNumber!=='' && this.state.cogChangePhoneNumber!=='' ){
      
          var state={ 
            'cogPhoneAreaNumber':this.state.cogChangePhoneAreaNumber, 
            'cogPhoneNumber':this.state.cogChangePhoneNumber,
            'cogPhoneExtNumber':this.state.cogChangePhoneExtNumber,
            'cogContact':this.state.cogChangeContact,
            'cogFaxNumber':this.state.cogChangeFaxNumber,
            'cogEngName':this.state.cogChangeEngName,
            'cogNPWP':this.state.cogChangeNPWP,
            'cogZipCode':this.state.cogChangeZipCode,
            'cogEngAddressRegion':this.state.cogChangeEngAddressRegion,
            'cogEngAddressCity':this.state.cogChangeEngAddressCity,
            'cogEngAddress':this.state.cogChangeEngAddress,
            'orderPassWay':this.state.orderPassWay,
            'orderChargeFee':this.state.orderChargeFee,
            'orderChargeExtFee':this.state.orderChargeExtFee
            
          }
          ref.child('/'+this.state.customerNumber+'/consignee/'+(this.state.cogChangePhoneAreaNumber+this.state.cogChangePhoneNumber)).set(state).then(() => {
            this.setState(
              {
                cogChangePhoneAreaNumber:'', 
                cogChangePhoneNumber:'',
                cogChangePhoneExtNumber:'',
                cogChangeContact:'',
                cogChangeFaxNumber:'',
                cogChangeEngName:'',
                cogChangeNPWP:'',
                cogChangeZipCode:'',
                cogChangeEngAddressRegion:'',
                cogChangeEngAddressCity:'',
                cogChangeEngAddress:'',
                orderPassWay: 'PIBK',
                orderChargeFee:300,
                orderChargeExtFee:'',
              }
            )
          }) 
          
          
      }else{
        this.setState({cogChangemessage:'電話不可以是空的!'}) 
      }

    
    
  }


  //尋找單筆歷史訂單資料
  searchOrder(){

    //把今天的年份和月份先找出來
    let date = new Date();
    let nowYear=date.getFullYear()
    let nowMonth= date.getMonth()+1       //month要加1，因為0是一月
    
    //當單筆訂單輸入的時候，我就安排了一個訂單單號紀錄的方法，這樣方便我找到這張訂單單號是什麼時候的
    //因為新增訂單的時候，我就把單號存了兩次，所以查這個月的資料，就可以找到上個月的紀錄
    //historyOrderRecord 就是我現在要查詢的歷史單號
    ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/poDate/'+nowYear+'/'+nowMonth).orderByKey().equalTo(this.state.historyOrderRecord).once('value', (snapshot)=> {
      if(snapshot.val()){
            //console.log(snapshot.val())
            let target=snapshot.val()[this.state.historyOrderRecord]
            let year=target.slice(0,4)
            let month=target.slice(4,6)
            let day=target.slice(6,8)
            //console.log(year,month,day)
            ref.child('/'+this.state.customerNumber+'/'+this.state.orderTaxWay+'/'+year+'/'+month+'/'+day+'/'+this.state.historyOrderRecord).once('value', (snapshot)=> {
              if(snapshot.val()){
                let targetDeliveryNumber=this.state.historyOrderRecord
                this.setState(snapshot.val())
                this.setState({
                  historyOrderRecord:'',
                  historyRecordChangePath:target,
                  historyRecordOrderDeliveryNumber:targetDeliveryNumber,
                  changeHistoryPO:true,
                  historyRecordChangeInfo:''
                })
              }           
            })
      }else{
        //找不到訂單
        this.setState({
          historyRecordChangeInfo:'查無此訂單',
          historyRecordChangePath:'',
          changeHistoryPO:false,
        })
      } 
      
    })
  }
  

  

 
 
  

  


  render() {
    
    //下面是箱數材積的計算內容
    var orderPackageNumber=this.state.pcBoxSize;
    var boxInput = [];
    for(var i=0;i<orderPackageNumber;i++){
        
        var boxNumber=i+1
        boxInput.push(
                       <Form.Group key={boxNumber} >
                            <Form.Field width='3'>
                                <label>類別</label>
                                <label style={{'font-size':'24px' }}>{boxNumber}</label>
                            </Form.Field>
                            
                            <Form.Field width='5'>
                                <label>箱數</label>
                                <input  placeholder='箱數' value={this.state['pcBox'+boxNumber]} name={'pcBox'+boxNumber} onChange={this.pcBoxCalculate}/>
                            </Form.Field>
                            <Form.Field width='10'>
                                <label>長</label>
                                <input  placeholder='長' value={this.state['LBox'+boxNumber]} name={'LBox'+boxNumber} onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field width='10'>
                                <label>寬</label>
                                <input  placeholder='寬' value={this.state['WBox'+boxNumber]} name={'WBox'+boxNumber} onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field width='10'>
                                <label>高</label>
                            <input  placeholder='高' value={this.state['HBox'+boxNumber]} name={'HBox'+boxNumber} onChange={this.handleInputChange}/>
                            </Form.Field>
                            
                        </Form.Group>
        )    　
    }

    //下面是報關內容的填寫介面
    var itemNumber=this.state.itemPcNumber;
    var itemInput = [];
    for(var i=0;i<itemNumber;i++){
        
        var number=i+1
        itemInput.push(
                      <Form.Group key={i} widths='equal'>
                            <label style={{'font-size':'36px' }}>{number}</label>
                            <Form.Field>
                                <label>品名</label>
                                <input  placeholder='請輸入品名' value={this.state['itemName'+number]} name={'itemName'+number} onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>HS Code</label>
                                <input  placeholder='請輸入HS Code' value={this.state['itemHScode'+number]} name={'itemHScode'+number} onChange={this.handleInputChange}/>
                            </Form.Field>
                            <Form.Field>
                                <label>件數</label>
                            <input  placeholder='請輸入件數' value={this.state['itemNumber'+number]} name={'itemNumber'+number} onChange={this.handleTotalPCs}/>
                            </Form.Field>
                            <Form.Field>
                                <label>單品總價USD$</label>
                                <input  placeholder='請輸入總價' value={this.state['itemUnitPrice'+number]} name={'itemUnitPrice'+number} onChange={this.handlePriceValueCalculate}/>
                            </Form.Field>
                     </Form.Group>
        )    　
    }
    
  //這裡我要根據目前選擇的包稅方式顯示訂單內容
  let displayContent;
  if (this.state.orderTaxWay === 'withTax') {
    displayContent = this.state.todayWithTaxPOContent;
  } else {
    displayContent = this.state.todayWithoutTaxPOContent;
  }
     
    
    return (
    
     <div>
      {/* 
     <div style={{ 'padding-top':'0.2em','padding-bottom':'0.3em',height:'','text-align': 'center','font-size':'28px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
            <Image size='medium' rounded centered inline src='/img/logo.png' style={{ 'padding-top':'',height:'' }}/>
                             印尼Key單系統        
     </div>
      */}
                                          
      {/* 主要輸入介面 */}
      <div style={{ 'padding-top': '1.3em','padding-left': '2em','padding-right': '2em'}}>
      <Grid divided='vertically' centered>
            <Grid.Row centered style={{ 'padding-top':'0.2em','padding-bottom':'0em',height:'1.5em','font-size':'30px','font-weight':'bold',color:'black' }}> 印尼Key單系統</Grid.Row>      
            
            <Grid.Row columns={1} style={{ 'padding-top':'0em','padding-bottom':'0em',height:'8em' }}>
                  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em' }}>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                    <Label as='a' color='red' ribbon>
                    訂單類型
                    </Label>
                    
                    <Form>
                        <Form.Group style={{ height: '3.2em'}}>
                            
                            
                            <Form.Field  width='3'>
                                <label>訂單類別選擇</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                <Form.Field
                                    control={Radio}
                                    label='包稅'
                                    value='withTax'
                                    checked={this.state.orderTaxWay === 'withTax'}
                                    onChange={this.orderTaxWay}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='不包稅'
                                    value='withoutTax'
                                    checked={this.state.orderTaxWay  === 'withoutTax'}
                                    onChange={this.orderTaxWay}
                                />
                                </Form.Group>

                            </Form.Field>
                            
                        </Form.Group>
                        
                        
                    </Form>
                    
                    </Segment>
                  </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={{ 'padding-top':'0em','padding-bottom':'0em',height:'27em' }}>
                  <Grid.Column >
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                    <Label as='a' color='red' ribbon>
                    寄件公司資料
                    </Label>
                    
                    <Form >
                    <Form.Group style={{ height: '3em'}}>
                            
                            
                            <Form.Field  width='10'>
                                <label>報關方式</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                <Form.Field
                                    control={Radio}
                                    label='簡易'
                                    value='simple'
                                    checked={this.state.shipperCustomsClearance === 'simple'}
                                    onChange={this.customsClearanceChange}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='正式'
                                    value='formal'
                                    checked={this.state.shipperCustomsClearance === 'formal'}
                                    onChange={this.customsClearanceChange}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='無設定'
                                    value=''
                                    checked={this.state.shipperCustomsClearance === ''}
                                    onChange={this.customsClearanceChange}
                                />
                                </Form.Group>

                            </Form.Field>
                            
                        </Form.Group>
                        <Form.Group widths='equal' id='shipperForm' style={{ height: '3.6em'}}> 
                            <Form.Field>
                                <label>寄件方聯絡電話-區號+電話</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                        <Form.Field style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='電話號碼' value={this.state.shipperPhoneNumber} name="shipperPhoneNumber" onChange={this.handleShipperPhoneChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        <Form.Field width='1'>
                                        </Form.Field>
                                        <Form.Field width='3' style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='分機' value={this.state.shipperPhoneExtNumber} name="shipperPhoneExtNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}} />
                                        </Form.Field>                                                             
                                </Form.Group>
                            </Form.Field>
                            <Form.Field>
                                <label>寄件方聯絡人-請填英文</label>
                                <input placeholder='聯絡人英文姓名' value={this.state.shipperContact}  name="shipperContact" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                           
                            <Form.Field>
                                <label>客戶編號</label>
                                <input placeholder='請輸入客戶編號' value={this.state.shipperAccount}  name="shipperAccount" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>寄件方公司中文名</label>
                                <input placeholder='公司中文名稱' value={this.state.shipperChName} name="shipperChName" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>寄件方公司英文名</label>
                                <input placeholder='公司英文名稱' value={this.state.shipperEngName} name="shipperEngName" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group >
                            <Form.Field width='16' >
                                <label >寄件方公司中文地址</label>
                                <input  placeholder='請輸入寄件公司中文地址' value={this.state.shipperChAddress} name="shipperChAddress" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group >
                            <Form.Field width='16'>
                                <label>寄件方公司英文地址</label>
                                <input  placeholder='請輸入寄件公司英文地址'   value={this.state.shipperEngAddress} name="shipperEngAddress"  onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    
                    </Segment>
                  </Grid.Column>
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em' }}>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                    <Label as='a' color='red' ribbon>
                    訂單基本資料
                    </Label>
                    
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>提單編號</label>
                                <input  placeholder='請輸入提單編號' value={this.state.orderDeliveryNumber} name="orderDeliveryNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field  style={{ 'padding-top':'1.6em'}}>
                                
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>件數</label>
                                <input  placeholder='請輸入件數' value={this.state.orderPackageNumber} name="orderPackageNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>總重</label>
                                <input placeholder='請輸入重量' value={this.state.orderTotalWeight} name="orderTotalWeight" onChange={this.orderTotalWeight} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>材積重</label>
                                <Modal trigger={<input placeholder='請點擊輸入' value={this.state.orderVolumeWeight} name="orderVolumeWeight"  style={{ padding: '0.3em'}}/>}
                                        onClose={this.volumeWeightCalculate}>
                                   <Modal.Header>
                                      <Grid columns={2} divided>
                                          <Grid.Row>
                                            <Grid.Column width='10'>
                                                  請依欄位輸入各箱的長寬高尺寸-公分為單位<br/>
                                                <Label color='grey'>輸入完畢後，點擊暗黑處回復</Label> 
                                                <Button icon='plus' label='加一種尺寸'  size='mini' color='orange'   onClick={this.addPcBoxSize} style={{ 'font-size':'10px'}}/>  
                                                <Button icon='minus' label='少一種尺寸'  size='mini' color='blue'  onClick={this.cutPcBoxSize} style={{ 'font-size':'10px'}}/>   
                                            </Grid.Column>
                                            <Grid.Column width='6'>
                                                <Label color={this.state.boxOrigialNumber} style={{ 'margin-right': '3em'}}>
                                                  寄送總箱數
                                                  <Label.Detail style={{ 'font-size':'25px','color':'black'}}>{this.state.orderPackageNumber}</Label.Detail>
                                                </Label>
                                                <Label color={this.state.boxAlreadySign}>
                                                  已分配箱數
                                                  <Label.Detail style={{ 'font-size':'25px','color':'black'}}>{this.state.pcBoxTotal}</Label.Detail>
                                                </Label>
                                            </Grid.Column>
                                          
                                          </Grid.Row>
                                        </Grid>              
                                    </Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                        {boxInput}
                                        </Form>
                                        
                                    </Modal.Content>
                                </Modal>
                            </Form.Field>
                            
                        </Form.Group >
                        <Form.Group style={{ height: '3.2em'}}>
                            <Form.Field width='5'>
                                <label>箱號(單箱，填第一格即可)</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                        <Form.Field width='5' style={{ 'padding-left': '0.4em','padding-right': '0em'}}>
                                            <input   placeholder='箱號' value={this.state.orderLoadingBox1} name="orderLoadingBox1" onChange={this.handleBoxInputChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        {/* 這是中間的分隔號 */}
                                        <Form.Field width='2' style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                        <label style={{ 'text-align': 'center'}}><Icon name='window minimize' /></label>
                                        </Form.Field>
                                        <Form.Field width='5' style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='箱號' value={this.state.orderLoadingBox2} name="orderLoadingBox2" onChange={this.handleBoxInputChange} style={{ padding: '0.3em'}} />
                                        </Form.Field>                                                             
                                </Form.Group>
                            </Form.Field>
                            
                            <Form.Field  width='3'>
                                <label>運費支付方式</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                <Form.Field
                                    control={Radio}
                                    label='PP'
                                    value='PP'
                                    checked={this.state.orderPayWay === 'PP'}
                                    onChange={this.orderPayWay}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='CC'
                                    value='CC'
                                    checked={this.state.orderPayWay  === 'CC'}
                                    onChange={this.orderPayWay}
                                />
                                </Form.Group>

                            </Form.Field>
                            
                        </Form.Group>
                        <Form.Group style={{ 'padding-top': '0.5em'}} >
                            <Form.Field width='16'>
                                <label>備註</label>
                                <input  placeholder='請填' value={this.state.orderNote} name="orderNote" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                <label>{this.state.sizeNote}</label>
                            </Form.Field>
                        </Form.Group>
                        
                    </Form>
                    
                    </Segment>
                  </Grid.Column>
            </Grid.Row>
           
         
            <Grid.Row columns={2} style={{ 'padding-top':'0em','padding-bottom':'0em',height:'30em' }}>
                  <Grid.Column stretched style={{height:'28em'}}>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                    <Label as='a' color='red' ribbon>
                    進口方公司資料
                    </Label>
                    
                    <Form>
                    <Form.Group>
                        <Form.Field style={{ 'padding-top': '1em','padding-left': '0.8em'}}>
                        <input type="checkbox" checked={this.state.receiverTheSame} size='massive' onChange={this.cogCheck} />
                        </Form.Field>
                        <Form.Field style={{ 'padding-top': '0.5em'}}>
                        <Label color='orange' style={{ 'font-size':'12px'}}>進口方同收件方(請勾選)</Label>
                        </Form.Field>
                        <Form.Field style={{ 'padding-top': '0.5em'}}>
                        <Label as='a' tag  color='yellow' style={{ 'font-size':'12px'}}>
                                        運費總價${this.state.currency}<Label.Detail style={{ 'color':'red','font-size':'15px'}}>{this.state.orderTotalFee}</Label.Detail>
                              </Label>
                        </Form.Field>
                    
                    </Form.Group > 
                    
                    <Form.Group  style={{ height: '3em','padding-buttom': '0em'}}>
                            <Form.Field  width='6'> 
                                <label>進口方聯絡電話</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                        
                                        <Form.Field width='3' style={{ 'padding-left': '','padding-right': '0em'}}>
                                            <input   placeholder='區碼' value={this.state.cogPhoneAreaNumber} name="cogPhoneAreaNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        <Form.Field style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='電話號碼' value={this.state.cogPhoneNumber} name="cogPhoneNumber" onChange={this.handleCogPhoneChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        <Form.Field width='3' style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='分機' value={this.state.cogPhoneExtNumber} name="cogPhoneExtNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}} />
                                        </Form.Field>                                                             
                                </Form.Group>
                               
                            </Form.Field>
                            <Form.Field width='4'>
                                <label>進口方聯絡人</label>
                                <input  placeholder='進口方聯絡人姓名' value={this.state.cogContact} name="cogContact" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field width='6'>
                                <label>進口方傳真電話</label>
                                <input  placeholder='傳真電話' value={this.state.cogFaxNumber} name="cogFaxNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal' style={{ 'padding-top':'0em',height: '3em'}}>
                            <Form.Field>
                                <label>進口方公司英文名</label>
                                <input  placeholder='進口方公司英文名' value={this.state.cogEngName} name="cogEngName" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>NPWP稅號</label>
                                <input placeholder='請輸入進口方稅號' value={this.state.cogNPWP} name="cogNPWP" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>郵政編號</label>
                                <input placeholder='請填郵政編號' value={this.state.cogZipCode} name="cogZipCode" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>英文地址1-City</label>
                                <input placeholder='英文地址' value={this.state.cogEngAddressCity} name="cogEngAddressCity" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                
                            </Form.Field>
                            <Form.Field>
                                <label>英文地址2-Region</label>
                                <input placeholder='英文地址' value={this.state.cogEngAddressRegion} name="cogEngAddressRegion" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>英文地址3</label>
                                <input placeholder='請填入英文地址' value={this.state.cogEngAddress} name="cogEngAddress" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            
                            
                        </Form.Group>
                        <Form.Group widths='equal'>
                              <Form.Field  width='4' >
                                      <label>通關方式</label>
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                      <Form.Field
                                          control={Radio}
                                          label='PIBK'
                                          value='PIBK'
                                          checked={this.state.orderPassWay === 'PIBK'}
                                          onChange={this.orderPassWay}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='FTZ'
                                          value='FTZ'
                                          checked={this.state.orderPassWay  === 'FTZ'}
                                          onChange={this.orderPassWay}
                                      />
                                      </Form.Group>

                                </Form.Field>
                                <Form.Field  width='4'>
                                <label>運費基本價-$/KG</label>
                                <input placeholder='請填數字' value={this.state.orderChargeFee} name="orderChargeFee" onChange={this.orderChargeFee} style={{ padding: '0.3em'}}/>
                                </Form.Field>
                                <Form.Field  width='4'>
                                <label>運費遠程加價-$/KG</label>
                                <input placeholder='請填數字' value={this.state.orderChargeExtFee} name="orderChargeExtFee" onChange={this.orderChargeExtFee} style={{ padding: '0.3em'}}/>
                                </Form.Field>
                            
                            
                        </Form.Group>
                        
                        
                    </Form>
                    
                    </Segment>
                  </Grid.Column>
                  <Grid.Column stretched style={{height:'23em'}}>
                  {!this.state.receiverTheSame?(
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                    <Label as='a' color='red' ribbon>
                    收件方公司資料
                    </Label>
                    
                    <Form style={{ 'padding-top':'3.4em'}}>
                    
                    
                        <Form.Group  style={{ height: '3.5em'}}>
                            <Form.Field  width='6'> 
                                <label>收件方聯絡電話</label>
                                <Form.Group style={{ 'padding-right': '0em'}} >
                                        
                                        <Form.Field width='3' style={{ 'padding-left': '','padding-right': '0em'}}>
                                            <input   placeholder='區碼' value={this.state.recPhoneAreaNumber} name="recPhoneAreaNumber"  onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        <Form.Field style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='電話號碼' value={this.state.recPhoneNumber} name="recPhoneNumber"  onChange={this.handleRecPhoneChange} style={{ padding: '0.3em'}}/>
                                        </Form.Field>
                                        <Form.Field width='3' style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                            <input   placeholder='分機' value={this.state.recPhoneExtNumber} name="recPhoneExtNumber"  onChange={this.handleInputChange} style={{ padding: '0.3em'}} />
                                        </Form.Field>                                                             
                                </Form.Group>
                               
                            </Form.Field>
                            <Form.Field width='4'>
                                <label>收件方聯絡人</label>
                                <input  placeholder='收件方聯絡人' value={this.state.recContact}  name="recContact" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field width='6'>
                                <label>收件方傳真電話</label>
                                <input  placeholder='收件方傳真電話' value={this.state.recFaxNumber} name="recFaxNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>收件方公司英文名</label>
                                <input  placeholder='收件方英文名' value={this.state.recEngName} name="recEngName" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>NPWP稅號</label>
                                <input placeholder='NPWP稅號' value={this.state.recNPWP} name="recNPWP" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>郵政編號</label>
                                <input placeholder='請填郵政編號' value={this.state.recZipCode} name="recZipCode" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>英文地址1-City</label>
                                <input placeholder='英文地址' value={this.state.recEngAddressCity} name="recEngAddressCity" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            <Form.Field>
                                <label>英文地址2-Region</label>
                                <input placeholder='英文地址' value={this.state.recEngAddressRegion} name="recEngAddressRegion" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>英文地址3</label>
                                <input placeholder='請填入英文地址' value={this.state.recEngAddress} name="recEngAddress" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                            </Form.Field>
                            
                            
                        </Form.Group>
                        
                        
                    </Form>
                    
                    </Segment>):null}
                    
                  </Grid.Column>
                  
            </Grid.Row>
            
            <Grid.Row style={{ 'padding-top':'0em','padding-bottom':'0em' }}>
                  <Grid.Column width='10'>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                    <Label as='a' color='red' ribbon>
                    寄送內容
                    </Label>
                      
                    <Form>
                        <Form.Group style={{ 'padding-top':'0.5em'}}>
                           <Button icon='plus' label='加一個品項'  size='mini' color='orange' onClick={this.addItem} style={{ 'font-size':'10px'}}/>
                           <Button icon='minus' label='減一個品項'  size='mini' color='blue' onClick={this.cutItem} style={{ 'font-size':'10px'}}/>
                           <Label as='a' tag  color='yellow' style={{ 'font-size':'12px'}}>
                                    寄件總件數<Label.Detail style={{ 'color':'red','font-size':'24px'}}>{this.state.totalPCs}</Label.Detail>
                           </Label>
                           <Label as='a' tag  color='yellow' style={{ 'font-size':'12px'}}>
                                    報關總值USD$<Label.Detail style={{ 'color':'red','font-size':'24px'}}>{this.state.totalValue}</Label.Detail>
                           </Label>
                        </Form.Group>
                    
                        
                        {itemInput}
                        
                        
                    </Form>
                    
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width='6'>
                  <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                     <Form>
                        <Form.Group widths='equal'>
                                
                            <Form.Field style={{ 'padding-top':'1.2em','padding-bottom':'0em' }}>                                 
                                <Modal
                                    trigger={<Button icon='plus' label='本筆訂單存檔'  size='medium' color='red' onClick={this.saveFile} style={{ 'font-size':'12px','font-weight':'bold'}}/>}
                                    open={this.state.saveFilefinish}
                                    basic
                                    size='small'
                                    
                                >   
                                    <Grid centered>
                                    <Label color='green' centered floating onClick={this.handleSaveFileClose}><Icon size='huge' name='close'/></Label>
                                             <div style={{ 'font-size':'60px'}}>
                                                 資料存檔完成   
                                             </div>                                           
                                    </Grid>                                   
                                </Modal>
                            </Form.Field>
                            <Form.Field style={{ 'padding-top':'1.5em' }}>
                                 {this.state.message?
                                   <Label >{this.state.message}</Label>:<div></div> }
                            </Form.Field>
                        </Form.Group>
                                             
                     </Form>
                   </Segment>
                  </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            {/* 今日訂單總表 */} 
            <Grid.Column stretched>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                      <Label as='a' color='green' ribbon>
                        {this.state.today}報單紀錄列表
                      </Label>
                      
                      <Form>
                          <Form.Group style={{ 'padding-top':'0.5em'}}>
                            <Button icon='file alternate outline' label='下載Excel檔案'  size='small' color='orange' onClick={this.exportFile} style={{ 'font-size':'12px'}}/>
                            <Button icon='qrcode' label='下載標籤檔案'  size='small' color='orange' onClick={this.handleLablePrint} style={{ 'font-size':'12px'}}/>
                          </Form.Group>
                          <Table celled >
                              <Table.Header>
                              <Table.Row>
                                  <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>勾選</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>編號</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>提單編號</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>寄件公司名稱</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>箱號</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>件數</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>總重</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>PP</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>CC</Grid></Table.HeaderCell>
                                  <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>報關總值</Grid></Table.HeaderCell>
                              </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                  {displayContent}
                              </Table.Body>

                          </Table>
                       </Form>
                    
                     </Segment>
               </Grid.Column>

            </Grid.Row>

            {/* 刪除今日訂單資料 */} 
            <Grid.Row>

            <Grid.Column stretched>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                      <Label as='a' color='green' ribbon>
                        刪除今日訂單資料
                      </Label>
                      
                      <Form>
                          <Form.Group style={{ 'padding-top':'0.5em'}}>
                            <Form.Field>
                                <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入欲刪除的提單編號</Label>                                 
                                <Modal
                                    trigger={<Input
                                                action={{
                                                  content:'刪除本筆訂單紀錄',
                                                  onClick: () => this.setState({deleteHistoryOpen:true})
                                                }}
                                                name='deleteHistory'
                                                value={this.state.deleteHistory}
                                                onChange={this.handleInputChange}
                                                style={{ 'padding-top':'1em'}}
                                              />
                                            }
                                    open={this.state.deleteHistoryOpen}
                                    basic
                                    size='small'
                                >   
                                    <Grid centered>
                                            <div style={{ 'font-size':'36px'}}>
                                                您確認刪除{this.state.deleteHistory}這筆訂單  
                                            </div> 
                                            <Label color='red' centered  onClick={this.deleteHistory} style={{ 'padding-top':'0.5em','font-size':'16px'}}>確認</Label>                                          
                                    </Grid>                                   
                                </Modal>
                            </Form.Field> 
                            
                          </Form.Group>
                          
                      </Form>
                    
                    </Segment>
              </Grid.Column>

            </Grid.Row>

  
            {/* 查詢歷史資料 */} 
            <Grid.Row>

            <Grid.Column stretched>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                      <Label as='a' color='green' ribbon>
                        查詢歷史資料
                      </Label>
                      
                      <Form>
                      <Form.Group style={{ 'padding-top':'0.5em'}}>
                          <Form.Field>
                            下載單日資料只要輸入開始日期即可
                          </Form.Field>
                      </Form.Group>
                          <Form.Group style={{ 'padding-top':'0.5em'}}>
                          {/* <Form.Field>
                            <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入八碼日期碼，譬如2020年1月1號，請輸20200101</Label>
                            <Input
                              action={{
                                content:'下載Excel檔',
                                //下面這是當你按下excel鍵的時候，要啟動的動作
                                onClick: () => this.historyExportFile()  
                              }}
                              //但是不要忘了，當你把資料輸進來的時候，我們需要把這個值存起來，等一下才有辦法處理
                              name='historyRecord'
                              value={this.state.historyRecord}
                              onChange={this.handleInputChange}
                              style={{ 'padding-top':'1em'}}
                            />
                          </Form.Field>  */}
                                <Form.Field width='3'>
                                      <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入開始日期</Label>
                                        <Input
                                          name='historyRecordStart'
                                          value={this.state.historyRecordStart}
                                          onChange={this.handleInputChange}
                                          style={{ 'padding-top':'1em','padding-bottom':'1em'}}
                                        />
                                        
                                </Form.Field>
                                <Form.Field width='3'>
                                      <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入結束日期</Label>
                                        <Input
                                          name='historyRecordEnd'
                                          value={this.state.historyRecordEnd}
                                          onChange={this.handleInputChange}
                                          style={{ 'padding-top':'1em','padding-bottom':'1em'}}
                                        />
                                      
                                </Form.Field>
                            
                          </Form.Group>
                          <Form.Group>
                                <Button  color='red' style={{'font-size':'14px'}}  onClick={this.historyExportFile}>下載Excel檔</Button>
                                <Button  color='green' style={{'font-size':'14px','margin-left': '4em'}}  onClick={this.historyExportLabel}>下載標籤檔案</Button>
                                <Form.Field style={{ 'padding-top':'1.5em' }}>
                                 {this.state.historyErrorMessage?
                                   <Label >{this.state.historyErrorMessage}</Label>:<div></div> }
                                 </Form.Field>
                          </Form.Group>
                       </Form>
                    
                     </Segment>
               </Grid.Column>

            </Grid.Row>

            {/* 修改單筆歷史資料 */} 
            <Grid.Row>

            <Grid.Column stretched>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                      <Label as='a' color='green' ribbon>
                        查詢單筆歷史資料
                      </Label>
                      
                      <Form>
                          <Form.Group style={{ 'padding-top':'0.5em'}}>
                          <Form.Field>
                          <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入快遞單號</Label>
                          <Input
                            action={{
                              content:'查詢歷史訂單',
                              //下面這是當你按下excel鍵的時候，要啟動的動作
                              onClick: () => this.searchOrder()  
                            }}
                            //但是不要忘了，當你把資料輸進來的時候，我們需要把這個值存起來，等一下才有辦法處理
                            name='historyOrderRecord'
                            value={this.state.historyOrderRecord}
                            onChange={this.handleInputChange}
                            style={{ 'padding-top':'1em'}}
                          />
                          </Form.Field> 

                          </Form.Group>
                          <Form.Group>
                              <Form.Field  style={{ 'color':'red'}}>

                                {this.state.historyRecordChangePath?
                                  this.state.historyRecordOrderDeliveryNumber+'的訂單日期'+this.state.historyRecordChangePath:
                                  <></>
                                }
                                {this.state.historyRecordChangeInfo?
                                  this.state.historyRecordChangeInfo:
                                  <></>
                                }

                              </Form.Field>
                          </Form.Group>
                        
                          
                       </Form>
                    
                     </Segment>
               </Grid.Column>

            </Grid.Row>

            {/* 暫時存放區 */} 
            <Grid.Row>

            <Grid.Column stretched>
                    <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                      <Label as='a' color='green' ribbon>
                        暫時存放資料夾
                      </Label>
                      
                      <Form>
                          <Form.Group style={{ 'padding-top':'0.5em'}}>
                              <Form.Field>
                                  <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入目標訂單的快遞單號</Label>
                                  <Input
                                    name='tempTarget'
                                    value={this.state.tempTarget}
                                    onChange={this.handleInputChange}
                                    style={{ 'padding-top':'1em','padding-bottom':'1em'}}
                                  />
                                  <Button  color='red' style={{'font-size':'14px'}}  onClick={this.orderToTemp}>轉入暫存資料夾</Button>
                                  <Button  color='green' style={{'font-size':'14px'}}  onClick={this.tempToOrder}>轉回出貨資料夾</Button>
                                  <Button  color='pink' style={{'font-size':'14px'}}  onClick={this.tempToOrder}>刪除暫存資料夾訂單</Button>
                              </Form.Field>                               
                          </Form.Group>
                          <Form.Group>
                              <Form.Field>
                                   <Table celled style={{'width':300 }}>
                                        <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                            <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>寄件方</Grid></Table.HeaderCell>
                                  
                                        </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {this.state.tempDataOnScreen}
                                        </Table.Body>
                                    </Table>
                              </Form.Field>
                          </Form.Group>
                          
                       </Form>
                    
                     </Segment>
               </Grid.Column>

            </Grid.Row>

             {/* 修改客戶資料區 */} 
             <Grid.Row>

                <Grid.Column stretched>
                        <Segment raised style={{ 'padding-top':'0em','padding-bottom':'0em' }}>  
                          <Label as='a' color='green' ribbon>
                            修改進口方客戶資料
                          </Label>
                          
                          <Form>
                              <Form.Group style={{ 'padding-top':'0.5em'}}>
                                  <Form.Field>
                                      <Label color='orange' style={{ 'padding-top':'0.5em'}}>請輸入進口方電話(區碼+電話)</Label>
                                      <Input
                                        action={{
                                          content:'查詢',
                                          onClick: () => this.searchCogData()
                                        }}
                                        name='cogSearchData'
                                        value={this.state.cogSearchData}
                                        onChange={this.handleInputChange}
                                        style={{ 'padding-top':'1em'}}
                                      />
                                  </Form.Field>                               
                              </Form.Group>
                              <Form.Group>
                                <Form.Field>                               
                                  <Form.Group  style={{ height: '3em','padding-buttom': '0em'}}>
                                      <Form.Field  width='6'> 
                                          <label>進口方聯絡電話</label>
                                          <Form.Group style={{ 'padding-right': '0em'}} >
                                                  
                                                  <Form.Field width='3' style={{ 'padding-left': '','padding-right': '0em'}}>
                                                      <input   placeholder='區碼' value={this.state.cogChangePhoneAreaNumber} name="cogChangePhoneAreaNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                                  </Form.Field>
                                                  <Form.Field style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                                      <input   placeholder='電話號碼' value={this.state.cogChangePhoneNumber} name="cogChangePhoneNumber" onChange={this.handleCogPhoneChange} style={{ padding: '0.3em'}}/>
                                                  </Form.Field>
                                                  <Form.Field width='3' style={{ 'padding-left': '0.1em','padding-right': '0em'}}>
                                                      <input   placeholder='分機' value={this.state.cogChangePhoneExtNumber} name="cogChangePhoneExtNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}} />
                                                  </Form.Field>                                                             
                                          </Form.Group>
                                        
                                      </Form.Field>
                                      <Form.Field width='4'>
                                          <label>進口方聯絡人</label>
                                          <input  placeholder='進口方聯絡人姓名' value={this.state.cogChangeContact} name="cogChangeContact" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                      <Form.Field width='6'>
                                          <label>進口方傳真電話</label>
                                          <input  placeholder='傳真電話' value={this.state.cogChangeFaxNumber} name="cogChangeFaxNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                  </Form.Group>
                                  <Form.Group widths='equal' style={{ 'padding-top':'0em',height: '3em'}}>
                                      <Form.Field>
                                          <label>進口方公司英文名</label>
                                          <input  placeholder='進口方公司英文名' value={this.state.cogChangeEngName} name="cogChangeEngName" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                      <Form.Field>
                                          <label>NPWP稅號</label>
                                          <input placeholder='請輸入進口方稅號' value={this.state.cogChangeNPWP} name="cogChangeNPWP" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                  </Form.Group>
                                  <Form.Group widths='equal'>
                                      <Form.Field>
                                          <label>郵政編號</label>
                                          <input placeholder='請填郵政編號' value={this.state.cogChangeZipCode} name="cogChangeZipCode" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                      <Form.Field>
                                          <label>英文地址1-City</label>
                                          <input placeholder='英文地址' value={this.state.cogChangeEngAddressCity} name="cogChangeEngAddressCity" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                          
                                      </Form.Field>
                                      <Form.Field>
                                          <label>英文地址2-Region</label>
                                          <input placeholder='英文地址' value={this.state.cogChangeEngAddressRegion} name="cogChangeEngAddressRegion" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                      
                                  </Form.Group>
                                  <Form.Group widths='equal'>
                                      <Form.Field>
                                          <label>英文地址3</label>
                                          <input placeholder='請填入英文地址' value={this.state.cogChangeEngAddress} name="cogChangeEngAddress" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                      
                                      
                                  </Form.Group>
                                  <Form.Group widths='equal'>
                                        <Form.Field  width='4' >
                                                <label>通關方式</label>
                                                <Form.Group style={{ 'padding-right': '0em'}} >
                                                <Form.Field
                                                    control={Radio}
                                                    label='PIBK'
                                                    value='PIBK'
                                                    checked={this.state.orderPassWay === 'PIBK'}
                                                    onChange={this.orderPassWay}
                                                />
                                                <Form.Field
                                                    control={Radio}
                                                    label='FTZ'
                                                    value='FTZ'
                                                    checked={this.state.orderPassWay  === 'FTZ'}
                                                    onChange={this.orderPassWay}
                                                />
                                                </Form.Group>

                                          </Form.Field>
                                          <Form.Field  width='4'>
                                          <label>運費基本價-$/KG</label>
                                          <input placeholder='請填數字' value={this.state.orderChargeFee} name="orderChargeFee" onChange={this.orderChargeFee} style={{ padding: '0.3em'}}/>
                                          </Form.Field>
                                          <Form.Field  width='4'>
                                          <label>運費遠程加價-$/KG</label>
                                          <input placeholder='請填數字' value={this.state.orderChargeExtFee} name="orderChargeExtFee" onChange={this.orderChargeExtFee} style={{ padding: '0.3em'}}/>
                                          </Form.Field>
                                      
                                      
                                  </Form.Group>
                                </Form.Field>     
                                  
                              </Form.Group>

                              <Form.Group style={{ 'padding-top':'0.5em'}}>
                              <Form.Field>
                                  <Button  color='pink' style={{'font-size':'14px'}}  onClick={this.cogDataChangeSave}>存檔</Button>
                              </Form.Field> 

                              {/* 下面要處理找不到客戶資料的訊息顯示*/}  
                              <Form.Field style={{ 'padding-top':'1.5em' }}>
                                 {this.state.cogChangemessage?
                                   <Label >{this.state.cogChangemessage}</Label>:<div></div> }
                              </Form.Field>                             
                          </Form.Group>
                              
                          </Form>
                        
                        </Segment>
                  </Grid.Column>

              </Grid.Row>

         
            
     </Grid>
     
      </div>
     </div>




    );
  }
}

export default App;